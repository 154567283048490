import React, { useContext, useEffect, useState } from "react";
import { KYCModalLayout } from "@/components/Modal";
import { LoanKYCModal } from "@/components/Modal/KYC/LoanKYCModal";
import { KYC_STATE_MAP } from "@/utils/constant";
import { firstLetterCapital } from "@/utils";
import { custom_context } from "@/utils/custom_context";
import { getLoanRequest, getKycStatus, getApplicationStatusRequest } from "@/services";
import { getStorage, setStorage } from "@/utils/storage";
import { Stack, Spinner, Button } from "react-bootstrap";

import ConnectSellerPlatform from './components/connect_seller_platform';
import CreditEvaluation from "./components/credit_evaluation";
import AccountSetup from "./components/account_setup";
import UploadInvoice from "./components/upload_invoice";

export const renderRejectionMessage = (status, message) => (
  message && message !== "None" && message !== "" && ['input_required', 'rejected'].includes(status) && (
    <div className="dash_alertDiv">
      <p>{message}</p>
    </div>
  )
);

export function Dashboard() {
  const { showKycModal, setShowKycModal } = useContext(custom_context.ModalContext);
  const [loanDetails, setLoanDetails] = useState({});
  const [applicationStatus, setApplicationStatus] = useState({});
  const [showLoanKyc, setShowLoanKyc] = useState(false);
  const [kycDetails, setKycDetails] = useState({});
  const { profileData, setProfileData } = useContext(custom_context.ProfileData);
  const [kycLoading, setKycLoading] = useState(false);
  const [loanLoading, setLoanLoading] = useState(false);

  useEffect(() => {
    if (!showKycModal) getLoanDetails();
  }, [showLoanKyc]);

  useEffect(() => {
    // getKycDetails();
    getApplicationStatus()
  }, [showKycModal]);

  const getApplicationStatus = async () => {
    try {
      const response = await getApplicationStatusRequest();
      if (response?.status === 200) {
        const updatedUserDetails = {
          ...getStorage("logged-in")?.result,
          application_status: response.results,
        };
        setStorage("logged-in", { ...getStorage("logged-in"), result: updatedUserDetails });
        setProfileData(updatedUserDetails);
        setApplicationStatus(response.results)
        setKycDetails(response?.results?.kyc);
      } else { }
    } catch (err) {
      console.log('Error fetching Application Status', err);
    }
  };

  const getLoanDetails = async () => {
    setLoanLoading(true);
    try {
      const resp = await getLoanRequest();
      if (resp?.status) {
        setLoanDetails(resp?.results[0]);
      }
    } catch (error) {
      console.error("Error fetching loan details:", error);
    } finally {
      setLoanLoading(false);
    }
  };

  const kycStatus = kycDetails?.status || "incomplete";
  const userKycStatus = KYC_STATE_MAP[kycStatus]?.label;
  const isKYCReadOnly = userKycStatus === "Completed";
  const loanStatus = loanDetails?.status || "incomplete";
  const isLoanStatusCompleted = loanStatus === "completed";
  const showLoanActionButton = loanDetails?.merchant ? "Update" : "Upload";

  const renderKycStatus = () => (
    <div className={`dash_pendingDiv ${["In Review", "Completed"].includes(userKycStatus) ? "success" : ""}`}>
      <span className={`${["In Review", "Completed"].includes(userKycStatus) ? "success" : ""}`}>
        {userKycStatus}
      </span>
    </div>
  );

  return (
    <section className="_welCome_fincoBox">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="outerFinco_welcome _kycCompletionBx">

              <div className="dash_containerDiv">
                <span className="_adminName">
                  Hi, {firstLetterCapital(profileData?.first_name)} {firstLetterCapital(profileData?.last_name)}
                </span>
                <Stack gap={3}>
                  <div className="dash_containSubDiv">
                    <div className="dash_contentDiv">
                      <div className="dash_statusDiv">
                        <h4>
                          KYC Status- {kycDetails?.completion_percentage || 0}% Completed
                        </h4>
                        {kycLoading ? (
                          <></>
                        ) : (
                          renderKycStatus()
                        )}
                      </div>
                      {(!kycLoading && !isKYCReadOnly) && <div className="dash_statusPara">
                        <p>You can click Update Details to modify and resubmit correct details</p>
                      </div>
                      }

                    </div>
                    {!isKYCReadOnly && (
                      <Button
                        color="primary"
                        className="minWidth"
                        onClick={() => setShowKycModal(true)}
                        disabled={kycLoading}
                      >
                        {kycLoading ? <Spinner size="sm" /> : "Update"}
                      </Button>
                    )}
                    {renderRejectionMessage(kycDetails?.status, kycDetails?.rejection_reason)}
                  </div>
                  <div className="dash_containSubDiv">
                    <div className="dash_contentDiv">
                      <div className="dash_statusDiv">
                        <h4>Upload Documents</h4>
                        {
                          kycLoading ? <></> : <div className={`dash_pendingDiv ${["submitted_for_review", "completed"].includes(loanStatus) ? "success" : ""}`}>
                            <span className={`${["submitted_for_review", "completed"].includes(loanStatus) ? "success" : ""}`}>
                              {KYC_STATE_MAP[loanStatus]?.label}
                            </span>
                          </div>
                        }
                      </div>
                      {loanLoading ? (
                        <Spinner size="sm" />
                      ) : (
                        loanStatus !== "completed" && (
                          <div className="dash_statusPara">
                            <p>{loanLoading ? "" : "Please upload your financial documents"}</p>
                          </div>
                        )
                      )}
                    </div>
                    <div>
                      {
                        !isLoanStatusCompleted && <Button
                          color="primary"
                          className="minWidth"
                          onClick={() => setShowLoanKyc(loanDetails || {})}
                          disabled={loanLoading}
                        >
                          {loanLoading ? <Spinner size="sm" /> : showLoanActionButton}
                        </Button>
                      }
                    </div>
                    {renderRejectionMessage(loanDetails?.status, loanDetails?.rejection_reason)}
                  </div>
                  <ConnectSellerPlatform />
                  <CreditEvaluation disabled documentStatus={loanStatus} />
                  <AccountSetup loanId={loanDetails?.id} />
                  <UploadInvoice disabled={true} />
                </Stack>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showLoanKyc && (
        <LoanKYCModal
          show={showLoanKyc}
          onHide={() => { getLoanDetails(); setShowLoanKyc(false); }}
          callLoan={getLoanDetails}
          loanDetails={loanDetails}
        />
      )}
      {showKycModal && <KYCModalLayout show={showKycModal} setShow={setShowKycModal} />}
    </section>
  );
}
