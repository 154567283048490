import config from "./_apiConfig/apiConfig";
import { performRequest, apiEndPoints, methodType } from "./_apiConfig";

export const createLoanRequest = (data = {}) => {
  return performRequest(
    methodType.POST,
    config.API_BASE_URL + apiEndPoints.createLoan,
    data,
    true,
    true
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getLoanRequest = (data = {}) => {
  return performRequest(
    methodType.GET,
    config.API_BASE_URL + apiEndPoints.createLoan,
    data,
    true
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateLoanRequest = async (data = {}, id) => {
  return performRequest(
    methodType.PATCH,
    apiEndPoints.createLoan + "/" + id,
    data,
    true,
    true
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteLoanDocumentRequest = (id) => {
  return performRequest(
    methodType.DELETE,
    config.API_BASE_URL + apiEndPoints.loanDocument + "/" + id,
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const createLoanDocumentRequest = (data = {}) => {
  return performRequest(
    methodType.POST,
    config.API_BASE_URL + apiEndPoints.loanDocument,
    data,
    true,
    true
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getEvaluation = (data = {}) => {
  return performRequest(
    methodType.GET,
    config.API_BASE_URL + apiEndPoints.evaluation,
    data,
    true
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getSettlementBankAccounts = (data = {}) => {
  return performRequest(
    methodType.GET,
    config.API_BASE_URL + apiEndPoints.settlementBankAcount,
    data,
    true
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateBankAccountRequest = async (data = {}, id) => {
  return performRequest(
    methodType.PATCH,
    apiEndPoints.settlementBankAcount + "/" + id,
    data,
    true,
    true
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const createBankAccountRequest = (data = {}) => {
  return performRequest(
    methodType.POST,
    config.API_BASE_URL + apiEndPoints.settlementBankAcount,
    data,
    true,
    true
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getVirtualAccountsRequest = (data = {}) => {
  return performRequest(
    methodType.GET,
    config.API_BASE_URL + apiEndPoints.virtualAccount(data?.loanId),
    data,
    true
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const uploadVirtualAccountDocument = (data = {}, loanId, vaId) => {
  return performRequest(
    methodType.POST,
    config.API_BASE_URL + apiEndPoints.virtualAccountDocument(loanId, vaId),
    data,
    true,
    true
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteVaDocumentRequest = (data = {}, loanId, vaId, documentId) => {
  return performRequest(
    methodType.DELETE,
    config.API_BASE_URL + apiEndPoints.virtualAccountDocument(loanId, vaId) + `/${documentId}`,
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};