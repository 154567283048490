import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import arrImg from "@/assets/cst_whtArrow.png";
import { loanFormSchema } from "../../../utils/error_schema";
import {
  checkFormError,
  isObjectValueEmpty,
} from "../../../utils";
import {
  FloatingField,
  InputField,
  SelectField,
} from "../../Common";
import PhoneInput from "react-phone-input-2";
import {
  platformList,
  financingList,
} from "../../../utils/constant";
import { createLoanRequest, updateLoanRequest } from "../../../services";
import Loader from "../../Common/Loader";
import { custom_context } from "../../../utils/custom_context";
import { toast } from "react-toastify";
import { SuccessKYCModal } from "./SuccessKYCModal ";
import { UploadLoanDocuments } from "./upload_loan_documents";
import "./kyc.css";

export const LoanKYCModal = (props) => {
  const { profileData } = useContext(custom_context.ProfileData);
  const { show, onHide, callLoan, loanDetails } = props;
  const initialValue = {
    merchant: profileData?.id,
    merchant_type: (profileData?.business_type).toLowerCase(),
    email: profileData?.email,
    phone: profileData?.phone_number,
    platform: null,
    platform_name: null,
    type_of_finacing: null,
    loan_documents: [],
    status: "submitted_for_review",
  };
  const [inputValue, setInputValue] = useState(!!show?.id ? show : initialValue);
  const [inputError, setInputError] = useState("");
  const [showResponseError, setResError] = useState();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAnchorInput, setShowAnchorInput] = useState(false);
  const [loanDocuments, setLoanDocuments] = useState([]);

  useEffect(() => {
    if (show?.id) {
      if (platformList.length && financingList.length) {
        const platformLabel = platformList.find(
          (item) => item.value == show?.platform
        );
        const financingLabel = financingList.find(
          (item) => item.value == show?.type_of_finacing
        );
        setInputValue((s) => {
          const obj = {
            ...s,
            ...show,
            platform: platformLabel?.value
              ? {
                value: platformLabel?.value,
                label: platformLabel?.label,
              }
              : null,
            type_of_finacing: financingLabel?.value
              ? {
                value: financingLabel?.value,
                label: financingLabel?.label,
              }
              : null,
          };
          return obj;
        });
        setShowAnchorInput(show.platform === 'other')
      }
      setLoanDocuments(
        loanDetails?.loan_documents.map((item) => ({
          ...item,
          loan_documents: item?.loan_documents,
          id: item?.id,
        }))
      )
    }
  }, [loanDetails]);

  const submit = async (e) => {
    e.preventDefault();
    const bankStatements = loanDocuments.filter(doc => doc.document_type === 'bank_statements');
    const invoices = loanDocuments.filter(doc => doc.document_type === 'invoices');

    if (!(invoices?.length > 0)) {
      toast.error('please upload atleast 1 Invoice');
      return
    }
    if (!(bankStatements?.length > 0)) {
      toast.error('please upload atleast 1 Bank Statement');
      return
    }
    const formData = new FormData();
    formData.append("merchant_type", inputValue?.merchant_type);
    formData.append("merchant", inputValue?.merchant);
    formData.append("email", inputValue?.email);
    formData.append("platform_name", inputValue?.platform_name);
    formData.append("phone", inputValue?.phone);
    formData.append("platform", inputValue?.platform?.value);
    formData.append("type_of_finacing", inputValue?.type_of_finacing?.value);
    if (!show?.id) {
      // Handle Loan Documents
      loanDocuments?.forEach((loanDocument) => {
        if (typeof loanDocument?.document !== "string" && loanDocument?.document) {
          formData.append("loan_document_files", loanDocument.document);
          formData.append("document_type", loanDocument.document_type);
        }
      });
    }
    formData.append("status", "submitted_for_review");

    const validationResult = await checkFormError(inputValue, loanFormSchema);
    if (validationResult === true) {
      setIsLoading(true);
      if (show?.id) {
        handleUpdate(formData, show?.id);
      } else {
        handleCreate(formData);
      }
    } else {
      setInputError(validationResult);
    }
  };

  const handleCreate = async (data) => {
    const resp = await createLoanRequest(data);
    if (resp?.status === 201) {
      toast.success(resp?.message, { position: "top-center" });
      onHide();
      setShowSuccessModal(true);
    } else {
      setIsLoading(false);
      setResError(resp?.results?.message);
    }
  };

  const handleUpdate = async (data, id) => {
    const resp = await updateLoanRequest(data, id);
    if (resp?.status === 200) {
      toast.success(resp?.message, { position: "top-center" });
      onHide();
    } else {
      setIsLoading(false);
      setResError(resp?.results?.message);
    }
  };

  const handleOnChange = async (name, value) => {
    const stateObj = { ...inputValue, [name]: value };
    if (name === 'platform') {
      setShowAnchorInput(value?.value === 'other');
    }
    setInputValue(stateObj);
    if (!!inputError) {
      if (isObjectValueEmpty(inputError)) {
        const error = await checkFormError(stateObj, loanFormSchema);
        setInputError(error);
      }
    }
  };

  return (
    <>
      <Modal
        className={
          "anvKyc_tpModal " + (showSuccessModal ? "success_modal" : "")
        }
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        size="xl"
        // centered
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-90h"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Document Upload
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={submit}>
          <Modal.Body className="kycProc_otrBdy">
            <div className="kycProc_sdContent w-100 mx-5 my-3">
              <div className="kycContent_ht">
                <div className="_innerKyc_grid">
                  <div className="_inKycHead">
                    <h1>General Information</h1>
                    <p>Please fill the form in order to apply for loan.</p>
                  </div>
                  <div className="_inFr_flexBx anvBas_select">
                    <FloatingField
                      controlId="floatingInput"
                      label="Email ID"
                      labelClass=""
                      type="text"
                      placeholder="Email ID"
                      name="email"
                      disabled
                      onChange={({ target: { name, value } }) =>
                        handleOnChange(name, value)
                      }
                      value={inputValue.email}
                    />
                    <div className="_inInput_fx _inDual_flex">
                      <InputField
                        inpId="anv_inpCont_si"
                        inputProps={
                          <PhoneInput
                            countryCodeEditable={false}
                            placeholder="Mobile Number"
                            className="text-input caret-transparent"
                            name="mobile_number"
                            country={"ae"}
                            onlyCountries={["ae"]}
                            autoComplete="off"
                          />
                        }
                      />
                      <FloatingField
                        controlId="floatingInput"
                        label="Mobile Number"
                        labelClass="anvMob_fq_bx"
                        type="number"
                        placeholder="Mobile Number"
                        name="phone"
                        disabled
                        defaultValue
                        maxLength={50}
                        onChange={({ target: { name, value } }) =>
                          handleOnChange(name, value)
                        }
                        value={inputValue.phone}
                      />
                    </div>
                    <SelectField
                      boxClass="basic-single"
                      classNamePrefix="select"
                      placeholder="Anchor Platform"
                      valueText="value"
                      labelText="label"
                      options={platformList}
                      name="platform"
                      error={
                        inputError?.platform
                          ? inputError?.platform
                          : inputError?.["platform.value"]
                      }
                      focus={
                        !!inputError?.["platform.value"] ||
                        !!inputError?.platform
                      }
                      selectedOption={inputValue.platform}
                      setSelectedOption={(value) =>
                        handleOnChange("platform", value)
                      }
                    />
                    {
                      showAnchorInput && (
                        <FloatingField
                          controlId="floatingInput"
                          label="Enter the Name of Anchor"
                          labelClass="_inInput_fx"
                          type="text"
                          placeholder="Anchor Name"
                          name="platform_name"
                          onChange={({ target: { name, value } }) =>
                            handleOnChange(name, value)
                          }
                          focus={!!inputError?.platform_name}
                          error={inputError?.platform_name}
                          value={inputValue.platform_name}
                        />
                      )
                    }
                    <SelectField
                      boxClass="basic-single"
                      classNamePrefix="select"
                      placeholder="Type of Financing"
                      valueText="value"
                      labelText="label"
                      options={financingList}
                      name="type_of_finacing"
                      error={
                        inputError?.type_of_finacing
                          ? inputError?.type_of_finacing
                          : inputError?.["type_of_finacing.value"]
                      }
                      focus={
                        !!inputError?.["type_of_finacing.value"] ||
                        !!inputError?.type_of_finacing
                      }
                      selectedOption={inputValue.type_of_finacing}
                      setSelectedOption={(value) =>
                        handleOnChange("type_of_finacing", value)
                      }
                    />
                  </div>
                  <UploadLoanDocuments loanDocuments={loanDocuments} setLoanDocuments={setLoanDocuments} loanId={show?.id} callLoan={callLoan} />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className={inputError?.resError && "anvFlex-root"}>
              {inputError?.resError && (
                <p className="invalid-feedback">
                  {showResponseError().map((item) => item)}
                </p>
              )}

              <Button
                variant="primary"
                type="submit"
                className="anvSv_btn"
                disabled={isLoading}
              >
                <span>
                  <span className="text-base">Save & Continue</span>
                  {isLoading ? (
                    <Loader className="loader_kycBtn" />
                  ) : (
                    <img src={arrImg} className="arrCon_btn" />
                  )}
                </span>
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
      {showSuccessModal && (
        <SuccessKYCModal
          show={showSuccessModal}
          setShow={() => {
            setShowSuccessModal(!showSuccessModal);
            onHide();
          }}
        />
      )}
    </>
  );
};
