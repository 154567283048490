import { FloatingLabel, Form } from "react-bootstrap";

export const FloatingField = (props) => {
  const { error, label, focus, labelClass, errorClass } = props;
  const invalid = !!(focus && error);
  const valid = !!(focus && !error);
  return (
    <>
      <FloatingLabel
        label={label}
        className={(labelClass ? labelClass : "") + " " + " _inInput_fx"}
      >
        <Form.Control
          controlId="floatingInput"
          autoComplete="off"
          isInvalid={invalid}
          valid={valid}
          {...props}
        />
        {invalid && <p className={errorClass + " invalid-feedback"}>{error}</p>}
      </FloatingLabel>
    </>
  );
};
