import React, { useContext, useEffect, useState } from "react";
import { Stack, Button, Card } from "react-bootstrap";
import { getIntegrationsRequest } from "@/services";
import { custom_context } from "../../../utils/custom_context";

import { ConnectSellerAccounts } from '@/components/Modal/application/connect_seller_accounts_modal';

import '../dashboard_styles.css';

function ConnectSellerPlatform({ disabled = false }) {
  const { profileData } = useContext(custom_context.ProfileData);
  const [integrationsLoading, setIntegrationsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [integrations, setIntegrations] = useState([]);
  const [bankAccountDetails, setBankAccountDetails] = useState({});

  let description = "Connect your Amazon, noon, woocommerce account to unlock higher credit limit";
  let disableCard = disabled;
  const isValidState = integrations.filter(integration => integration?.status === 'connected')?.length > 0;
  const showAction = true;

  useEffect(() => {
    getIntegrationDetails();
  }, [])

  const getIntegrationDetails = async () => {
    setIntegrationsLoading(true);
    try {
      const resp = await getIntegrationsRequest({ user_id: profileData?.id });
      if (resp?.status) {
        setIntegrations(resp?.results);
      }
    } catch (error) {
      console.error("Error fetching evaluation details:", error);
    } finally {
      setIntegrationsLoading(false);
    }
  };

  return (
    <Card className={`dashboardActionCard ${disableCard ? 'disabledCard' : 'activeCard'}`}>
      <Stack direction="horizontal" gap={3}>
        <Stack gap={2} className="actionContent">
          <h4 className="actionCardTitle dash_statusDiv">Connect Seller Platform
            {!integrationsLoading && isValidState &&
              <div className={`statusTag success`}>
                <span className={`${isValidState ? "success" : ""}`}>
                  {"Connected"}
                </span>
              </div>
            }
          </h4>
          {!integrationsLoading && <p className="actionCardDescription">{description}</p>}
        </Stack>
        <div>
          {
            showAction && <Button
              variant="primary"
              onClick={() => { setShowModal(true) }}
              disabled={!disableCard && !showAction}
              className="actionButton"
              color="primary"
            >
              {isValidState ? 'View' : 'Connect'}
            </Button>
          }
        </div>
      </Stack>
      <ConnectSellerAccounts integrations={integrations} showModal={showModal} onHide={() => { getIntegrationDetails(); setShowModal(false) }} />
    </Card >
  );
}

export default ConnectSellerPlatform;