export const KYC_INITIAL_FORM = {
  first_name: "",
  last_name: "",
  email: "",
  occupation: null,
  nationality: null,
  address: "",
  city: null,
  po_box: "",
  address_proof: "",
  emirates_id_number: "",
  emirates_id_expiry_date: "",
  emirates_id_front: "",
  emirates_id_back: "",
  passport_number: "",
  passport_issue_date: "",
  passport_expiry_date: "",
  passport_front: "",
  passport_back: "",
  business_name: "",
  company_type: null,
  business_segment: null,
  sme_segment: null,
  industry_sector: null,
  tin_number: "",
  trade_license_number: "",
  company_address: "",
  company_city: "",
  company_po_box: "",
  business_document: "",
  business_type: "",
  trade_license: "",
  coi: "",
  moa: "",
  aoa: "",
  partnership_deed: "",
};