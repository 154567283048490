import { useEffect, useState, useCallback } from "react";
import {
  getKycData,
  citiesRequest,
  industrySectorRequest,
  nationalityRequest,
  occupationRequest,
} from "@/services";
import { KYC_INITIAL_FORM } from "./constants";
import { firstLetterCapital } from "../../../utils";

export const useKycData = (businessType) => {
  const [kycDetails, setKycDetails] = useState({});
  const [inputValue, setInputValue] = useState(KYC_INITIAL_FORM);
  const [initialFormValue, setInitialFormValue] = useState(KYC_INITIAL_FORM);
  const [cityData, setCityData] = useState([]);
  const [occupationData, setOccupationData] = useState([]);
  const [nationalityData, setNationalityData] = useState([]);
  const [industrySectorData, setIndustrySectorData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (occupationData.length && cityData.length && nationalityData.length)
      formatInputData();
  }, [kycDetails, occupationData, cityData, nationalityData])

  const formatInputData = () => {
    if (
      occupationData.length &&
      cityData.length &&
      nationalityData.length &&
      kycDetails?.occupation
    ) {
      const occupationLabel = occupationData.find(
        (value) => value.id == kycDetails?.occupation
      );

      const nationalityLabel = nationalityData.find(
        (value) => value.id == kycDetails?.nationality
      );
      const cityLabel = cityData.find((value) => value.id == kycDetails?.city);

      const industrySectorLabel = industrySectorData.find(
        (value) => value.id == kycDetails?.industry_sector
      );
      setInputValue((s) => {
        const obj = {
          ...s,
          ...kycDetails,
          occupation: occupationLabel?.id
            ? {
              value: occupationLabel?.id,
              label: occupationLabel?.occupation,
            }
            : null,
          nationality: nationalityLabel?.id
            ? {
              value: nationalityLabel?.id,
              label: nationalityLabel?.nationality,
            }
            : null,
          city: cityLabel?.id
            ? {
              value: cityLabel?.id,
              label: cityLabel?.name,
            }
            : null,
          industry_sector: industrySectorLabel?.id
            ? {
              value: industrySectorLabel?.id,
              label: industrySectorLabel?.name,
            }
            : inputValue.industry_sector,
          business_segment: kycDetails?.business_segment
            ? {
              value: kycDetails?.business_segment,
              label: firstLetterCapital(kycDetails?.business_segment),
            }
            : null,
          company_type: kycDetails?.company_type
            ? {
              value: kycDetails?.company_type,
              label: firstLetterCapital(kycDetails?.company_type),
            }
            : null,
          sme_segment: kycDetails?.sme_segment
            ? {
              value: kycDetails?.sme_segment,
              label: firstLetterCapital(kycDetails?.sme_segment),
            }
            : null,
        };
        setInitialFormValue(obj);
        return obj;
      });
    }
  }
  // 🔹 Fetch All KYC Data in Parallel
  const fetchKycData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const [kycResp, cityResp, occResp, natResp, industryResp] = await Promise.all([
        getKycData(businessType),
        citiesRequest(),
        occupationRequest(),
        nationalityRequest(),
        industrySectorRequest(),
      ]);

      if (kycResp?.status) setKycDetails(kycResp?.results[0] || {});
      if (cityResp?.status) setCityData(cityResp?.results || []);
      if (occResp?.status) setOccupationData(occResp?.results || []);
      if (natResp?.status) setNationalityData(natResp?.results || []);
      if (industryResp?.status) setIndustrySectorData(industryResp?.results || []);
    } catch (err) {
      console.error("Error fetching KYC data:", err);
      setError("Failed to fetch KYC data. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [businessType]);

  // 🔹 Auto-fetch Data on Mount
  useEffect(() => {
    fetchKycData();
  }, [fetchKycData]);

  return {
    kycDetails,
    cityData,
    occupationData,
    nationalityData,
    industrySectorData,
    kycLoading: loading,
    error,
    refreshKycData: fetchKycData, // Allow manual refresh if needed
    initialFormValue,
    setInitialFormValue,
    inputValue,
    setInputValue
  };
};
