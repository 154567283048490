import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { Button } from 'react-bootstrap';
import { Stack } from "react-bootstrap";
import arrImg from "@/assets/cst_whtArrow.png";
import DropzoneField from "../../Common/DropzoneField";
import { MdDelete } from "react-icons/md";
import Loader from "../../Common/Loader";
import { uploadVirtualAccountDocument, deleteVaDocumentRequest } from "../../../services";
import { compareFiles } from '@/utils/utils';

import "./modal_styles.css";
import "../KYC/kyc.css";

export const VirtualAccountDetailsModal = ({ loanId, vaDetails, showModal, onHide, reloadVa, disableActions }) => {
  const { account_holder_name, account_number, swift_code, bank_name, bank_location } = vaDetails || {};
  const [selectedFiles, setSelectedFiles] = useState(vaDetails?.virtual_account_documents || []);
  const [uploading, setUploading] = useState(false);

  const openFileInNewTab = (file) => {
    if (file?.proof_file && typeof file?.proof_file === 'string') {
      window.open(file?.proof_file, "_blank");
    } else {
      const fileUrl = URL.createObjectURL(file);
      window.open(fileUrl, "_blank");
    }
  };

  useEffect(() => {
    setSelectedFiles(vaDetails?.virtual_account_documents);
  }, [vaDetails])

  // Handle file selection
  const handleOnChange = (file) => {
    setSelectedFiles([...selectedFiles, ...file]);
  };

  // Function to handle file upload
  const uploadVaProof = async () => {
    if (selectedFiles.length === 0) {
      alert('Please select at least one file to upload.');
      return;
    }
    const newFiles = selectedFiles.filter(file => !file.id);
    if (newFiles.length === 0) {
      onHide();
    } else {
      setUploading(true);
      const formData = new FormData();

      // Append all selected files
      newFiles.forEach(file => {
        if (!file.id) {
          formData.append('proof_files', file);
        }
      });

      try {
        const response = await uploadVirtualAccountDocument(formData, loanId, vaDetails?.id)
        if (response?.status === 200) {
          toast.success("Documents Uploaded Successfully!", { position: "top-center" });
          await reloadVa();
          onHide();
        } else {
          setUploading(false);
          toast.error(resp?.results?.message, { position: "top-center" });
        }
      } catch (error) {
        toast.error("Error Uploading Documents", { position: "top-center" });
      } finally {
        setUploading(false);
      }
    }
  };

  const handleRemoveFile = async (index, data) => {
    if (data?.id) {
      const response = await deleteVaDocumentRequest({}, loanId, vaDetails.id, data?.id)
      if (response.success) {
        await reloadVa();
      } else {
        toast.error(response.message)
      }
    } else {
      const updatedDocuments = [...selectedFiles];

      const itemIndexTobeDeleted = updatedDocuments.findIndex(existingFile => (compareFiles(data, existingFile)));

      if (itemIndexTobeDeleted !== -1) { // Check if the document exists
        updatedDocuments.splice(itemIndexTobeDeleted, 1); // Remove the document
        setSelectedFiles(updatedDocuments);
      }
    }
  };

  return (
    <Modal
      className={
        "anvKyc_tpModal"
      }
      show={showModal}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      size="xl"
      centered
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-90h"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Virtual Account Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body-container">
        <p className="virtual-account-description">Congratulations, your account has been successfully setup, Please share/update these details with your buyer/platform.</p>
        <Stack className="bank-details-container">
          <div>
            <label>Account Holder Name:</label>
            <div>{account_holder_name}</div>
          </div>
          <div>
            <label>Bank Name:</label>
            <div>{bank_name}</div>
          </div>
          <div>
            <label>Bank Location:</label>
            <div>{bank_location}</div>
          </div>
          <div>
            <label>IBAN:</label>
            <div>{account_number}</div>
          </div>
          <div>
            <label>SWIFT Code:</label>
            <div>{swift_code}</div>
          </div>
        </Stack>
        <div className="fileAdd_bx anvDropFile mt-4" style={{ width: '80%', margin: 'auto' }}>
          <div>
            <h6>Upload Proof</h6>
            <p className="my-2">Please upload the proof of account change similar to screen below for us activate your credit line</p>
            <div className="my-2" style={{ textAlign: 'center' }}>
              <img style={{ width: '100%', maxWidth: '550px' }} src="https://assets-fincobox.s3.ap-south-1.amazonaws.com/aws_deposit_account_change_proof.png" />
            </div>
          </div>
          <div
            className={
              (false
                ? "error "
                : "") + "_attachBx"
            }
          >
            <DropzoneField
              showFilePreview={false}
              disabled={disableActions}
              title={
                selectedFiles === "string"
                  ? selectedFiles
                  : selectedFiles?.name
              }
              htmlFor="attach_invoices"
              dropzoneProps={
                {
                  maxFiles: 2,
                  noDrag: true,
                  multiple: true,
                }
              }

              value={selectedFiles}
              handleOnChange={(value) =>
                handleOnChange(value)
              }
            />
          </div>
          <>
            {
              selectedFiles?.length > 0 && <div className="responSive_table">
                <table className="table table-striped invMod_table">
                  <thead>
                    <tr>
                      <th width={'20%'}>S.no</th>
                      <th>Uploaded Proof</th>
                      <th width={'10%'}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedFiles?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>
                            <button
                              type="button"
                              className="viewBtn_table"
                              onClick={() =>
                                openFileInNewTab(item)
                              }
                            >
                              View
                            </button>
                          </td>
                          <td>
                            <button
                              className={`invMod_delIcon ${disableActions && 'disabled'}`}
                              disabled={disableActions}
                              onClick={() => handleRemoveFile(i, item)}
                            >
                              <MdDelete />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            }
          </>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className={""}>
          <Button
            variant="primary"
            onClick={uploadVaProof}
            className="anvSv_btn"
            disabled={uploading}
          >
            <span>
              <span className="text-base">Submit</span>
              {uploading ? (
                <Loader className="loader_kycBtn" />
              ) : (
                <img src={arrImg} className="arrCon_btn" />
              )}
            </span>
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
