import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { setStorage } from "../../utils/storage";
import AuthLayout from "./AuthLayout";
import { loginSchema } from "../../utils/error_schema";
import { loginRequest } from "../../services";
import { FloatingField } from "../../components/Common";
import { IoMdEyeOff, IoMdEye } from "react-icons/io";
import Loader from "../../components/Common/Loader";
import { checkFormError, isObjectValueEmpty, queryString } from "../../utils";
import { InputField } from "../../components/Common";
import PhoneInput from "react-phone-input-2";
import "./auth.css";
import { isProd } from "../../services/_apiConfig/apiConfig";
const ALLOWED_COUNTRY_CODES = isProd ? ['ae'] : ['ae', 'in'];


export function Login() {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState({
    phone_number: "",
    country_code: "+971",
    password: "",
  });
  const [inputError, setInputError] = useState({
    phone_number: "",
    country_code: "",
    password: "",
  });
  const [resError, setResError] = useState("");
  const [toggleEye, setToggleEye] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    const validationResult = await checkFormError(inputValue, loginSchema);
    if (validationResult === true) {
      setIsLoading(true);
      const res = await loginRequest(inputValue);
      if (res?.success && res?.results?.token) {
        setStorage("logged-in", { ...res.results });
        navigate("/home");
      } else if (res?.results?.needs_verification) {
        setIsLoading(false);
        navigate(
          "/verification/" + res?.results?.user?.id + "?" + queryString(res?.results?.user)
        );
      } else {
        setIsLoading(false);
        setResError(res?.error?.message || 'Something went wrong!');
      }
    } else {
      setInputError(validationResult);
    }
  };

  const onInputChange = async (name, value) => {
    const stateObj = { ...inputValue, [name]: value };
    setInputValue(stateObj);
    if (isObjectValueEmpty(inputError)) {
      const error = await checkFormError(stateObj, loginSchema);
      setInputError(error);
    }
  };
  return (
    <AuthLayout title="Login">
      <form onSubmit={submit}>
        <div className="text-2xl mb-8">Welcome to Fincobox</div>
        <div className="mb-4 relative d-flex justify-content-between">
          <InputField
            inpId="anv_inpCont_si"
            inputProps={
              <PhoneInput
                countryCodeEditable={false}
                placeholder="Mobile Number"
                className="text-input caret-transparent"
                name="mobile_number"
                country={"ae"}
                onChange={(val, country, e, formattedvalue) => onInputChange('country_code', formattedvalue)}
                onlyCountries={ALLOWED_COUNTRY_CODES}
                autoComplete="off"
              />
            }
          />
          <FloatingField
            controlId="floatingInput"
            label="Mobile Number"
            labelClass="_inInput_fx _inInput_numFx"
            type="number"
            placeholder="Mobile Number"
            name="phone_number"
            defaultValue
            maxLength={50}
            onChange={({ target: { name, value } }) =>
              onInputChange(name, value)
            }
            focus={!!inputError?.phone_number}
            error={inputError.phone_number}
            value={inputValue.phone_number}
          />
        </div>
        <div className=" relativePos">
          <FloatingField
            controlId="floatingInput"
            label="Password"
            labelClass="_inInput_fx"
            type={toggleEye ? "text" : "password"}
            id="password1"
            placeholder="Password"
            name="password"
            maxLength={50}
            onChange={({ target: { name, value } }) =>
              onInputChange(name, value)
            }
            focus={!!inputError.password}
            error={inputError.password}
            value={inputValue.password}
          />

          <div className="anvEye_iconTogle">
            {inputError.password ? (
              ""
            ) : !toggleEye ? (
              <IoMdEye onClick={() => setToggleEye((s) => !s)} />
            ) : (
              <IoMdEyeOff onClick={() => setToggleEye((s) => !s)} />
            )}
          </div>
        </div>
        <div className="check_forget_align">
          <p className="mt-3 ">
            <Link to="/forgot-password">Forgot Password?</Link>
          </p>
        </div>
        <div className="mt-4">
          {resError && <p className="invalid-feedback">{resError}</p>}
          <button
            type="submit"
            className="button sub_primary mt-2"
            id="refUrl"
            disabled={isLoading}
          >
            <span>
              <span className="text-base">Log in</span>
              {isLoading ? (
                <Loader className="loader_cls" />
              ) : (
                <img
                  src="image/buttonArrow.27e5232.svg"
                  alt="icon"
                  className="absolute right-0 top-0 mt-3 mr-8"
                />
              )}
            </span>
          </button>
        </div>
        <p className="mt-3 text-center">
          Don't have an account? <Link to="/signup">Sign up now</Link>
        </p>
      </form>
    </AuthLayout>
  );
}
