import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import AuthLayout from "./AuthLayout";
import { verifyEmailRequest } from "../../services";  // Assuming you have a service for email verification
import Loader from "../../components/Common/Loader";

import './verifyEmail.css';

export function VerifyEmail() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isVerified, setIsVerified] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  // Extract token and user_id from the URL
  const params = new URLSearchParams(location.search);
  const token = params.get("token");
  const user_id = params.get("user_id");

  useEffect(() => {
    if (!token || !user_id) {
      setErrorMessage("Invalid verification link.");
      setIsLoading(false);
      return;
    }

    // Make the API call to verify the email
    const verifyUserEmail = async () => {
      try {
        const res = await verifyEmailRequest({ token, user_id });

        if (res?.success) {
          setIsVerified(true);
        } else {
          setIsVerified(false);
          setErrorMessage(res?.message || "Email verification failed.");
        }
      } catch (error) {
        setIsVerified(false);
        setErrorMessage("Something went wrong. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    verifyUserEmail();
  }, [token, user_id]);

  return (
    <AuthLayout title="Verify Email">
      <div className="p-4 text-center">
        <div className="text-2xl mb-8">Verify Your Email</div>
        <div className="mb-4">
          {isLoading ? (
            <div className="authVerifyEmailLoaderContainer">
              <Loader className="loader" />
            </div>
          ) : isVerified === true ? (
            <div className="text-green-500">Your email has been verified successfully!</div>
          ) : isVerified === false ? (
            <div className="text-red-500">{errorMessage}</div>
          ) : (
            <div className="text-red-500">{errorMessage}</div>
          )}
          <Link to="/login">Login</Link>
        </div>
      </div>
    </AuthLayout>
  );
}
