// To differentiate environments we use this variable passed in Package.json
export const env = import.meta.env.MODE;
export const isProd = import.meta.env.MODE === 'production';

const configList = {
  development: {
    CURRENT_ENV: env,
    API_BASE_URL: 'http://localhost:8000/api',
  },
  staging: {
    CURRENT_ENV: env,
    API_BASE_URL: 'https://uat-api.fincobox.com/api',
  },
  production: {
    CURRENT_ENV: env,
    API_BASE_URL: 'https://api.fincobox.com/api',
  },
};

export const APP_CONFIG = configList[env || 'development'];

export default APP_CONFIG;
