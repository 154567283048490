import React, { useContext } from "react";
import { Stack, Button, Card } from "react-bootstrap";
import { custom_context } from "../../../utils/custom_context";

import '../dashboard_styles.css';

function UploadInvoice() {
  const { profileData } = useContext(custom_context.ProfileData);
  const { application_status } = profileData || {};

  // enable card only if va is approved
  const disableCard = !(application_status?.virtual_account?.status === 'approved' && application_status?.evaluation?.status === 'approved');

  return (
    <Card className={`dashboardActionCard ${disableCard ? 'disabledCard' : 'activeCard'}`}>
      <Stack direction="horizontal" gap={3}>
        <Stack gap={2} className="actionContent">
          <h4 className="actionCardTitle">Upload Invoice</h4>
          <p className="actionCardDescription">Get started with uploading invoices in our dashboard and receive funds.</p>
        </Stack>
        <div>
          <a href="https://scfuat.fincobox.com" target="_blank">
            <Button
              variant="primary"
              className="actionButton"
              color="primary"
              disabled={false}
            >
              Upload
            </Button>
          </a>
        </div>
      </Stack>
    </Card >
  );
}

export default UploadInvoice;