import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { InputField, SelectField } from "../../components/Common";
import AuthLayout from "./AuthLayout";
import { signupSchema } from "../../utils/error_schema";
import { signupRequest } from "../../services";
import "./auth.css";

import { Form } from 'react-bootstrap';
import { FloatingField } from "../../components/Common";
import { IoMdEyeOff, IoMdEye } from "react-icons/io";
import {
  checkFormError,
  getResposeError,
  isObjectValueEmpty,
  queryString,
} from "../../utils";
import { Link } from "react-router-dom";
import Loader from "../../components/Common/Loader";
import {
  industrySectorRequest,
} from "@/services";
import { isProd } from "../../services/_apiConfig/apiConfig";
const ALLOWED_COUNTRY_CODES = isProd ? ['ae'] : ['ae', 'in'];

export function Signup() {
  const navigate = useNavigate();

  const [industrySectorData, setIndustrySectorData] = useState([]);
  const [inputValue, setInputValue] = useState({
    company_name: "",
    company_type: null,
    company_website: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    phone_number: "",
    user_type: "Borrower",
    country_code: '+971',
    accept_tnc: false,
    whatsapp_optin: false,
    accept_nda: false
  });
  const [inputError, setInputError] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    company_type: "",
    email: "",
    password: "",
    phone_number: "",
    user_type: "",
    accept_tnc: "",
    whatsapp_optin: "",
    accept_nda: ""
  });
  const [resError, setResError] = useState("");
  const [toggleEye, setToggleEye] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getIndustrySector = async () => {
    const resp = await industrySectorRequest();
    if (resp?.status) {
      setIndustrySectorData(resp?.results);
    }
  };

  useEffect(() => {
    getIndustrySector();
  }, []);


  const submit = async (e) => {
    e.preventDefault();
    const validationResult = await checkFormError(inputValue, signupSchema);
    if (validationResult === true) {
      setIsLoading(true);
      const { company_type, company_name, company_website, ...data } = inputValue;
      const res = await signupRequest({ ...data, company_details: { company_name, company_type, company_website }, business_type: "Business" });
      if (res?.success) {
        navigate(
          "/verification/" + res?.results?.id + "?" + queryString(res?.results)
        );
      } else {
        setIsLoading(false);
        setResError(getResposeError(res?.error));
      }
    } else {
      setInputError(validationResult);
    }
  };

  const onInputChange = async (name, value) => {
    const stateObj = { ...inputValue, [name]: value };
    setInputValue(stateObj);
    if (isObjectValueEmpty(inputError)) {
      const error = await checkFormError(stateObj, signupSchema);
      setInputError(error);
    }
  };

  return (
    <AuthLayout title="Login">
      <form onSubmit={submit}>
        <div className="text-2xl mb-8">
          We need a few details to enable your business growth
        </div>

        <div className="mb-4">
          <FloatingField
            controlId="floatingInput"
            label="Company Name"
            labelClass="_inInput_fx _inInput_signFx"
            type="text"
            id="company_name"
            placeholder="Company Name"
            name="company_name"
            maxLength={100}
            defaultValue
            onChange={({ target: { name, value } }) =>
              onInputChange(name, value)
            }
            focus={!!inputError.company_name}
            error={inputError.company_name}
            value={inputValue.company_name}
          />
        </div>
        <div className="mb-4 flex-1 _inFr_flexBx anvBas_select" style={{ display: "block" }}>
          <SelectField
            boxClass="basic-single"
            classNamePrefix="select"
            placeholder="Company Type"
            labelClass="_inInput_fx _inInput_signFx"
            valueText="id"
            labelText="name"
            options={industrySectorData}
            name="company_type"
            error={
              inputError?.company_type
                ? inputError?.company_type
                : inputError?.["company_type.value"]
            }
            focus={
              !!inputError?.["company_type.value"] ||
              !!inputError?.company_type
            }
            selectedOption={inputValue.company_type}
            setSelectedOption={(value) =>
              onInputChange("company_type", value)
            }
          />
        </div>
        <div className="mb-4">
          <FloatingField
            controlId="floatingInput"
            label="Company Website"
            labelClass="_inInput_fx _inInput_signFx"
            type="text"
            id="company_website"
            placeholder="Company Website"
            prefix="https://"
            name="company_website"
            maxLength={100}
            defaultValue
            onChange={({ target: { name, value } }) =>
              onInputChange(name, value)
            }
            focus={!!inputError.company_website}
            error={inputError.company_website}
            value={inputValue.company_website}
          />
        </div>
        <div className="mb-4 d-flex justify-content-between">
          <FloatingField
            controlId="floatingInput"
            label="First Name"
            labelClass="_inInput_fx _inInput_signFx"
            type="text"
            id="name"
            placeholder="Name"
            name="first_name"
            maxLength={100}
            defaultValue
            onChange={({ target: { name, value } }) =>
              onInputChange(name, value)
            }
            focus={!!inputError.first_name}
            error={inputError.first_name}
            value={inputValue.first_name}
          />
          <FloatingField
            controlId="floatingInput"
            label="Last Name"
            labelClass="_inInput_fx _inInput_signFx"
            type="text"
            id="name"
            placeholder="Name"
            name="last_name"
            maxLength={100}
            defaultValue
            onChange={({ target: { name, value } }) =>
              onInputChange(name, value)
            }
            focus={!!inputError.last_name}
            error={inputError.last_name}
            value={inputValue.last_name}
          />
        </div>

        <div className="mb-4 relative d-flex gap-2 justify-content-between">
          <InputField
            inpId="anv_inpCont_si"
            inputProps={
              <PhoneInput
                countryCodeEditable={false}
                placeholder="Mobile Number"
                className="text-input caret-transparent"
                name="mobile_number"
                country={"ae"}
                onChange={(val, country, e, formattedvalue) => onInputChange('country_code', formattedvalue)}
                onlyCountries={ALLOWED_COUNTRY_CODES}
                autoComplete="off"
              />
            }
          />
          <FloatingField
            controlId="floatingInput"
            label="Mobile Number"
            labelClass="flex-1 _inInput_fx _inInput_numFx flex-1"
            type="number"
            placeholder="Mobile Number"
            name="phone_number"
            defaultValue
            maxLength={50}
            onChange={({ target: { name, value } }) =>
              onInputChange(name, value)
            }
            focus={!!inputError?.phone_number}
            error={inputError.phone_number}
            value={inputValue.phone_number}
          />
        </div>
        <div className="mb-4">
          <FloatingField
            controlId="floatingInput"
            label="Email"
            labelClass="_inInput_fx"
            type="email"
            placeholder="Email"
            id="email"
            name="email"
            maxLength={50}
            defaultValue
            onChange={({ target: { name, value } }) =>
              onInputChange(name, value)
            }
            focus={!!inputError.email}
            error={inputError.email}
            value={inputValue.email}
          />
        </div>
        <div className="mb-5 relativePos anvModi_fi">
          <FloatingField
            controlId="floatingInput"
            label="Password"
            labelClass="_inInput_fx"
            type={toggleEye ? "text" : "password"}
            id="password1"
            placeholder="Set a new Password"
            className="password-input"
            name="password"
            maxLength={50}
            defaultValue
            onChange={({ target: { name, value } }) =>
              onInputChange(name, value)
            }
            focus={!!inputError?.password}
            error={inputError.password}
            value={inputValue.password}
          />

          <div className="anvEye_iconTogle">
            {!toggleEye ? (
              <IoMdEye onClick={() => setToggleEye((s) => !s)} />
            ) : (
              <IoMdEyeOff onClick={() => setToggleEye((s) => !s)} />
            )}
          </div>
        </div>
        <div >
          <Form.Check
            className="checkbox"
            name="accept_tnc"
            onChange={({ target: { name, checked } }) =>
              onInputChange(name, checked)
            }
            label={
              <>
                I have read and accept
                <a
                  href="https://www.fincobox.com/terms-and-conditions"
                  target="_blank"
                  className="text-tertiaryNeon underline px-1"
                >
                  Terms and Conditions
                </a>
                and Finco's
                <a href="https://www.fincobox.com/privacy-policy" target="_blank" className="text-tertiaryNeon underline px-1">Privacy Policy</a>
              </>
            }
          />
          {!!inputError?.accept_tnc && (
            <p className="invalid-feedback">{inputError?.accept_tnc}</p>
          )}
        </div>
        <div >
          <Form.Check
            className="checkbox"
            name="accept_nda"
            onChange={({ target: { name, checked } }) =>
              onInputChange(name, checked)
            }
            label={
              <>
                I have read and accept
                <a
                  href="https://www.fincobox.com/nda"
                  target="_blank"
                  className="text-tertiaryNeon underline px-1"
                >
                  Non Disclosure Agreement
                </a>
              </>
            }
          />
          {!!inputError?.accept_nda && (
            <p className="invalid-feedback">{inputError?.accept_nda}</p>
          )}
        </div>
        <div>
          <Form.Check
            name="whatsapp_optin"
            className="checkbox"
            onChange={({ target: { name, checked } }) =>
              onInputChange(name, checked)
            }
            label={
              <>
                Opt in for <strong>WhatsApp notifications</strong>
              </>
            }
          />
          {!!inputError?.whatsapp_optin && (
            <p className="invalid-feedback">{inputError?.whatsapp_optin}</p>
          )}
        </div>
        {resError && <p className="invalid-feedback">{resError}</p>}
        <div className="mt-4">
          <button
            type="submit"
            className="button sub_primary"
            id="refUrl"
            disabled={isLoading}
          >
            <span>
              <span className="text-base">Send OTP</span>
              {isLoading ? (
                <Loader className="loader_cls" />
              ) : (
                <img
                  src="image/buttonArrow.27e5232.svg"
                  alt="icon"
                  className="absolute right-0 top-0 mt-3 mr-8"
                />
              )}
            </span>
          </button>
        </div>
        <p className="mt-3 text-center">
          Already have an account? <Link to="/login">Sign In</Link>
        </p>
      </form>
    </AuthLayout>
  );
}
