import config from "./_apiConfig/apiConfig";
import { performRequest, apiEndPoints, methodType } from "./_apiConfig";

export const getIntegrationsRequest = (data = {}) => {
  return performRequest(
    methodType.GET,
    config.API_BASE_URL + apiEndPoints.fetchIntegrations,
    data,
    true
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getAmazonConsentRequest = (data = {}) => {
  return performRequest(
    methodType.GET,
    config.API_BASE_URL + apiEndPoints.connectAmazon,
    data,
    true
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}