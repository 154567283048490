import { Navigate, useLocation } from "react-router-dom";
import { getStorage } from "../utils/storage";
import { routerList } from "./route_list";
import { PublicRoutes } from "./public_routes";
import { ProtectRoutes } from "./protected_routes";

const loggedIn = getStorage('logged-in');

let list = routerList.map((route) => ({
  ...route,
  element: route.private ? (
    <ProtectRoutes showSidebar={route?.sidebar ?? 1}>
      <route.element />
    </ProtectRoutes>
  ) : (
    // Ensure 'verify-email' is rendered directly and doesn't go through public check
    route.path === "/verify-email" ? (
      <route.element />
    ) : (

      <PublicRoutes>
        <route.element />
      </PublicRoutes>
    )
  ),
}));

const QueryPreservingNavigate = ({ to, replace = false }) => {
  const location = useLocation();
  const queryParams = location.search; // Extract query params
  return <Navigate to={`${to}${queryParams}`} replace={replace} />;
};

const routes = [
  {
    path: "/",
    element: (
      <QueryPreservingNavigate to={loggedIn ? "/home" : "/login"} replace />
    ),
  },
  ...list, // Render public and private routes
  {
    path: "*",
    element: (
      <QueryPreservingNavigate to={loggedIn ? "/home" : "/login"} replace />
    ),
  },
];

export default routes;
