import React from 'react';

const styles = {
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    zIndex: 9999,
  },
  iframe: {
    width: '100%',
    height: '100%',
    border: 'none',
  }
};

export function InvoiceDashboard() {
  return (
    <div style={styles.container}>
      <iframe
        src={"https://scfuat.fincobox.com"}
        style={styles.iframe}
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </div>
  );
};