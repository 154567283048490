import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import arrImg from "@/assets/cst_whtArrow.png";
import { bankAccountSchema } from "../../../utils/error_schema";
import {
  checkFormError,
  isObjectValueEmpty,
} from "@/utils";
import {
  FloatingField,
  SelectField,
} from "../../Common";
import DropzoneField from "../../Common/DropzoneField";
import { banksRequest, citiesRequest, getSettlementBankAccounts, createBankAccountRequest, updateBankAccountRequest } from "../../../services";
import Loader from "../../Common/Loader";
import { custom_context } from "../../../utils/custom_context";
import { toast } from "react-toastify";
// import { SuccessKYCModal } from "./SuccessKYCModal ";
import "../KYC/kyc.css";

export const BankDetailsUpdateModal = ({ evaluationDetails, showModal, onHide }) => {
  const { profileData } = useContext(custom_context.ProfileData);
  const [cityList, setcityList] = useState([]);
  const [bankList, setbankList] = useState([]);
  const [bankAccountDetails, setBankAccountDetails] = useState({});

  const getCities = async () => {
    const resp = await citiesRequest();
    if (resp?.status) {
      setcityList(resp?.results);
    }
  };

  const getBanks = async () => {
    const resp = await banksRequest();
    if (resp?.status) {
      setbankList(resp?.results);
    }
  };

  const getBankAccountDetails = async () => {
    const resp = await getSettlementBankAccounts({ loanId: evaluationDetails?.loan_details?.id });
    if (resp?.status) {
      setBankAccountDetails(resp?.results[0]);
    }
  }

  useEffect(() => {
    getBanks();
    getCities();
    getBankAccountDetails();
  }, [])

  const initialValue = {
    merchant: profileData?.id,
    account_holder_name: '',
    bank: "",
    city: "",
    account_number: "",
    swift_code: "",
    bank_verification_proof: "",
  };

  const [inputValue, setInputValue] = useState(!!bankAccountDetails?.id ? bankAccountDetails : initialValue);
  const [inputError, setInputError] = useState("");
  const [showResponseError, setResError] = useState();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const formDisabled = ['under_review', 'verified'].includes(bankAccountDetails?.status);

  useEffect(() => {
    if (bankAccountDetails?.id) {
      if (bankList.length && cityList.length) {
        const bankLabel = bankList.find(
          (item) => item.id == bankAccountDetails?.bank
        );
        const cityLabel = cityList.find((value) => value.id == bankAccountDetails?.city);
        setInputValue((s) => {
          const obj = {
            ...s,
            ...bankAccountDetails,
            city: cityLabel?.name
              ? {
                value: cityLabel?.id,
                label: cityLabel?.name,
              }
              : null,
            bank: bankLabel?.name
              ? {
                value: bankLabel?.id,
                label: bankLabel?.name,
              }
              : null,
          };
          return obj;
        });
      }
    }
  }, [bankAccountDetails, cityList, bankList]);

  const onSubmitDetails = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("loan_id", evaluationDetails?.loan_details?.id)
    formData.append("account_holder_name", inputValue?.account_holder_name);
    formData.append("bank", inputValue?.bank?.value);
    formData.append("city", inputValue?.city?.value);
    formData.append("account_number", inputValue?.account_number);
    formData.append("swift_code", inputValue?.swift_code);
    formData.append("status", "under_review");

    if (!!inputValue['bank_account_proof'] &&
      typeof inputValue['bank_account_proof'] !== "string"
    ) {
      formData.append('bank_account_proof', inputValue['bank_account_proof']);
    }

    const validationResult = await checkFormError(inputValue, bankAccountSchema);

    if (validationResult === true) {
      setIsLoading(true);
      if (bankAccountDetails?.id) {
        handleUpdate(formData, bankAccountDetails?.id);
      } else {
        handleCreate(formData);
      }
    } else {
      setInputError(validationResult);
    }
  };

  const handleCreate = async (data) => {
    const resp = await createBankAccountRequest(data);
    if (resp?.status === 201) {
      toast.success(resp?.message, { position: "top-center" });
      onHide();
      setShowSuccessModal(true);
    } else {
      setIsLoading(false);
      setResError(resp?.results?.message);
    }
  };

  const handleUpdate = async (data, id) => {
    const resp = await updateBankAccountRequest(data, id);
    if (resp?.status === 200) {
      toast.success(resp?.message, { position: "top-center" });
      onHide();
    } else {
      setIsLoading(false);
      setResError(resp?.results?.message);
    }
  };

  const handleOnChange = async (name, value) => {
    const stateObj = { ...inputValue, [name]: value };
    setInputValue(stateObj);
    if (!!inputError) {
      if (isObjectValueEmpty(inputError)) {
        const error = await checkFormError(stateObj, bankAccountSchema);
        setInputError(error);
      }
    }
  };

  return (
    <>
      <Modal
        className={
          "anvKyc_tpModal " + (showSuccessModal ? "success_modal" : "")
        }
        show={showModal}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        size="xl"
        centered
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-90h"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Bank Account Details
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={onSubmitDetails}>
          <Modal.Body className="kycProc_otrBdy">
            <div className="kycProc_sdContent w-100 mx-5 my-3">
              <div className="kycContent_ht">
                <div className="_innerKyc_grid">
                  <div className="_inKycHead">
                    <h1>Offer Details</h1>
                  </div>
                  <FloatingField
                    controlId="floatingInput"
                    label="Amount"
                    labelClass=""
                    type="text"
                    placeholder="Offer Amount"
                    name="offer_amount"
                    disabled
                    value={`${evaluationDetails?.amount} AED`}
                  />

                  <iframe
                    title="Offer Letter"
                    src={evaluationDetails?.offer_letter}
                    width={"1040px"}
                    height={"800px"}
                  />


                  <hr />
                  <div className="_inKycHead">
                    <h1>Bank Details:</h1>
                  </div>
                  <div className="_inFr_flexBx anvBas_select">
                    <FloatingField
                      controlId="floatingInput"
                      label="Account Holder Name"
                      labelClass=""
                      type="text"
                      placeholder="Account Holder Name"
                      name="account_holder_name"
                      disabled={formDisabled}
                      error={
                        inputError?.account_holder_name
                          ? inputError?.account_holder_name
                          : inputError?.["account_holder_name.value"]
                      }
                      focus={
                        !!inputError?.["account_holder_name.value"] || !!inputError?.account_holder_name
                      }
                      onChange={({ target: { name, value } }) =>
                        handleOnChange(name, value)
                      }
                      value={inputValue.account_holder_name}
                    />
                    <SelectField
                      boxClass="basic-single"
                      classNamePrefix="select"
                      placeholder="Bank"
                      valueText="id"
                      labelText="name"
                      disabled={formDisabled}
                      options={bankList}
                      name="bank"
                      error={
                        inputError?.bank
                          ? inputError?.bank
                          : inputError?.["bank.value"]
                      }
                      focus={
                        !!inputError?.["bank.value"] || !!inputError?.bank
                      }
                      selectedOption={inputValue.bank}
                      setSelectedOption={(value) =>
                        handleOnChange("bank", value)
                      }
                    />
                    <SelectField
                      boxClass="basic-single"
                      classNamePrefix="select"
                      placeholder="City"
                      valueText="id"
                      labelText="name"
                      options={cityList}
                      disabled={formDisabled}
                      name="city"
                      error={
                        inputError?.city
                          ? inputError?.city
                          : inputError?.["city.value"]
                      }
                      focus={
                        !!inputError?.["city.value"] || !!inputError?.city
                      }
                      selectedOption={inputValue.city}
                      setSelectedOption={(value) =>
                        handleOnChange("city", value)
                      }
                    />
                    <FloatingField
                      controlId="floatingInput"
                      label="IBAN"
                      labelClass=""
                      type="text"
                      disabled={formDisabled}
                      placeholder="IBAN Number"
                      name="account_number"
                      error={
                        inputError?.account_number
                          ? inputError?.account_number
                          : inputError?.["account_number.value"]
                      }
                      focus={
                        !!inputError?.["account_number.value"] || !!inputError?.account_number
                      }
                      onChange={({ target: { name, value } }) =>
                        handleOnChange(name, value)
                      }
                      value={inputValue.account_number}
                    />

                    <FloatingField
                      controlId="floatingInput"
                      label="Enter Swift Code"
                      labelClass=""
                      type="text"
                      placeholder="Swift Code"
                      disabled={formDisabled}
                      name="swift_code"
                      error={
                        inputError?.swift_code
                          ? inputError?.swift_code
                          : inputError?.["swift_code.value"]
                      }
                      focus={
                        !!inputError?.["swift_code.value"] || !!inputError?.swift_code
                      }
                      onChange={({ target: { name, value } }) =>
                        handleOnChange(name, value)
                      }
                      value={inputValue.swift_code}
                    />
                  </div>

                  <div className="_inFr_flexBx">
                    <div className="fileAdd_bx">
                      <p>Bank Account Proof*</p>
                      <div
                        className={
                          (inputError?.bank_account_proof ? "error " : "") +
                          "_attachBx"
                        }
                      >
                        <DropzoneField
                          title="Address proof"
                          htmlFor="attach"
                          disabled={formDisabled}
                          value={
                            !!inputValue.bank_account_proof &&
                              typeof inputValue.bank_account_proof === "string" ? inputValue.bank_account_proof : null
                          }
                          handleOnChange={(value) =>
                            handleOnChange("bank_account_proof", value)
                          }
                        />
                      </div>
                      <span className="fil_addBottom_para">
                        (Upload your Bank Statement which showcases the above details for us to verify)
                      </span>

                      {!!inputError?.address_proof && (
                        <p className="invalid-feedback">
                          {inputError?.bank_account_proof}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className={inputError?.resError && "anvFlex-root"}>
              {inputError?.resError && (
                <p className="invalid-feedback">
                  {showResponseError().map((item) => item)}
                </p>
              )}

              <Button
                variant="primary"
                type="submit"
                className="anvSv_btn"
                disabled={isLoading}
              >
                <span>
                  <span className="text-base">Save & Continue</span>
                  {isLoading ? (
                    <Loader className="loader_kycBtn" />
                  ) : (
                    <img src={arrImg} className="arrCon_btn" />
                  )}
                </span>
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
      {/* {showSuccessModal && (
        <SuccessKYCModal
          show={showSuccessModal}
          setShow={() => {
            setShowSuccessModal(!showSuccessModal);
            onHide();
          }}
        />
      )} */}
    </>
  );
};
