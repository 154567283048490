import React from "react";
import {
  DateInput,
  FloatingField,
} from "../../Common";
import DropzoneField from "../../Common/DropzoneField";

import "./kyc.css";

const IdentityDetailsForm = ({
  inputValue, inputError, handleOnChange
}) => {
  return (
    <div className="_innerKyc_grid ing_exMarg">
      <div className="_inKycHead">
        <h1>Document Verification</h1>
        <p>
          We need a few more details about you and your business
        </p>
      </div>

      <div className="_inFr_flexBx">
        <div
          className="_inInput_fx fulWid _upLine_head"
          style={{ flex: "0 0 100%" }}
        >
          <h4>Emirate ID's</h4>
        </div>

        <div className="fileAdd_bx">
          <p className="_upLoad_uPara">
            Upload Emirates ID (front) *
          </p>
          <div
            className={
              (inputError?.emirates_id_front ? "error " : "") +
              "_attachBx"
            }
          >
            <DropzoneField
              title="Emirates ID Front"
              htmlFor="attach_2"
              value={
                !!inputValue.emirates_id_front &&
                typeof inputValue.emirates_id_front === "string"
              }
              handleOnChange={(value) =>
                handleOnChange("emirates_id_front", value)
              }
            />
          </div>
          {!!inputError?.emirates_id_front && (
            <p className="invalid-feedback">
              {inputError?.emirates_id_front}
            </p>
          )}
        </div>

        <div className="fileAdd_bx">
          <p className="_upLoad_uPara">
            Upload Emirates ID (Back) *
          </p>
          <div
            className={
              (inputError?.emirates_id_back ? "error " : "") +
              "_attachBx"
            }
          >
            <DropzoneField
              title="Emirates ID Back"
              htmlFor="attach_3"
              value={
                !!inputValue.emirates_id_back &&
                typeof inputValue.emirates_id_back === "string"
              }
              handleOnChange={(value) =>
                handleOnChange("emirates_id_back", value)
              }
            />
          </div>
          {!!inputError?.emirates_id_back && (
            <p className="invalid-feedback">
              {inputError?.emirates_id_back}
            </p>
          )}
        </div>
      </div>

      <div className="_inFr_flexBx">
        <FloatingField
          controlId="floatingInput"
          label="Enter your Emirate ID"
          labelClass="_inInput_fx"
          type="text"
          placeholder="Enter your Emirate ID"
          name="emirates_id_number"
          onChange={({ target: { name, value } }) =>
            handleOnChange(name, value)
          }
          focus={!!inputError?.emirates_id_number}
          error={inputError?.emirates_id_number}
          value={inputValue.emirates_id_number}
        />

        <fieldset
          className={`_anvDate_pickOuter_box ${!!inputError?.emirates_id_expiry_date ? " error" : ""
            }`}
        >
          <label
            htmlFor="Expiry_Date"
            className={
              inputValue?.emirates_id_expiry_date && "filled"
            }
          >
            Expiry Date <span>*</span>
          </label>
          <DateInput
            id="Expiry_Date"
            placeholderText="Expiry Date"
            name="emirates_id_expiry_date"
            dateFormat="dd-MMM-yyyy"
            value={
              inputValue?.emirates_id_expiry_date
                ? new Date(inputValue?.emirates_id_expiry_date)
                : ""
            }
            handleOnChange={(value) =>
              handleOnChange("emirates_id_expiry_date", value)
            }
            minDate={new Date()}
          />
          <i className="fa-regular fa-calendar"></i>
          {!!inputError?.emirates_id_expiry_date && (
            <p className="invalid-feedback">
              {inputError?.emirates_id_expiry_date}
            </p>
          )}
        </fieldset>
      </div>

      <div className="_inFr_flexBx">
        <div
          className="_inInput_fx fulWid _upLine_head"
          style={{ flex: "0 0 100%" }}
        >
          <h4>Passport Details</h4>
        </div>

        <div className="fileAdd_bx">
          <p className="_upLoad_uPara">
            Upload Passport page (Front) *
          </p>
          <div
            className={
              (inputError?.passport_front ? "error " : "") +
              "_attachBx"
            }
          >
            <DropzoneField
              title="Passport Front"
              htmlFor="attach_4"
              value={
                !!inputValue.passport_front &&
                typeof inputValue.passport_front === "string"
              }
              handleOnChange={(value) =>
                handleOnChange("passport_front", value)
              }
            />
          </div>
          {!!inputError?.passport_front && (
            <p className="invalid-feedback">
              {inputError?.passport_front}
            </p>
          )}
        </div>

        <div className="fileAdd_bx">
          <p className="_upLoad_uPara">
            Upload Passport page (Back) *
          </p>
          <div
            className={
              (inputError?.passport_back ? "error " : "") +
              "_attachBx"
            }
          >
            <DropzoneField
              title="Passport Back"
              htmlFor="attach_5"
              value={
                !!inputValue.passport_back &&
                typeof inputValue.passport_back === "string"
              }
              handleOnChange={(value) =>
                handleOnChange("passport_back", value)
              }
            />
          </div>
          {!!inputError?.passport_back && (
            <p className="invalid-feedback">
              {inputError?.passport_back}
            </p>
          )}
        </div>
      </div>

      <div className="_inFr_flexBx">
        <FloatingField
          controlId="floatingInput"
          label="Passport Number"
          labelClass="_inInput_fx"
          type="text"
          placeholder="Passport Number"
          name="passport_number"
          onChange={({ target: { name, value } }) =>
            handleOnChange(name, value)
          }
          focus={!!inputError?.passport_number}
          error={inputError?.passport_number}
          value={inputValue.passport_number}
        />
      </div>

      <div className="_inFr_flexBx">
        <fieldset
          className={`_anvDate_pickOuter_box ${!!inputError?.passport_issue_date ? " error" : ""
            }`}
        >
          <label
            htmlFor="passPort_issue"
            className={
              inputValue?.passport_issue_date && "filled"
            }
          >
            Passport Issue Date <span>*</span>
          </label>
          <DateInput
            id="passPort_issue"
            placeholderText="Passport Issue Date"
            value={
              inputValue?.passport_issue_date
                ? new Date(inputValue?.passport_issue_date)
                : ""
            }
            name="passport_issue_date"
            handleOnChange={(value) =>
              handleOnChange("passport_issue_date", value)
            }
            dateFormat="dd-MMM-yyyy"
            maxDate={new Date()}
          />
          <i className="fa-regular fa-calendar"></i>
          {!!inputError?.passport_issue_date && (
            <p className="invalid-feedback">
              {inputError?.passport_issue_date}
            </p>
          )}
        </fieldset>

        <fieldset
          className={`_anvDate_pickOuter_box ${!!inputError?.passport_expiry_date ? " error" : ""
            }`}
        >
          <label
            htmlFor="passPort_expired"
            className={
              inputValue?.passport_expiry_date && "filled"
            }
          >
            Passport Expiry Date <span>*</span>
          </label>
          <DateInput
            id="passPort_expired"
            placeholderText="Passport Expiry Date"
            value={
              inputValue?.passport_expiry_date
                ? new Date(inputValue?.passport_expiry_date)
                : ""
            }
            name="passport_expiry_date"
            handleOnChange={(value) =>
              handleOnChange("passport_expiry_date", value)
            }
            dateFormat="dd-MMM-yyyy"
            minDate={new Date()}
          />
          <i className="fa-regular fa-calendar"></i>
          {!!inputError?.passport_expiry_date && (
            <p className="invalid-feedback">
              {inputError?.passport_expiry_date}
            </p>
          )}
        </fieldset>
      </div>
    </div>
  )
}

export default IdentityDetailsForm;