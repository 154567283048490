import React, { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Card, Col, Row, Button, Spinner } from "react-bootstrap";
import { custom_context } from "../../../utils/custom_context";
import { toast } from "react-toastify";
import { getAmazonConsentRequest } from "../../../services";

import "./connect_seller_accounts_modal.css";

export const ConnectSellerAccounts = ({ integrations, showModal, onHide }) => {
  const { profileData } = useContext(custom_context.ProfileData);
  const [loading, setLoading] = useState(false);
  const connectedAmazonConnections = integrations.filter(integration => integration?.integration_name === 'AMAZON' && integration?.status === 'connected');
  const connectAmazonAccount = async () => {
    setLoading(true);
    try {
      const resp = await getAmazonConsentRequest({ user_id: profileData.id });
      if (resp?.consent_url) {
        setLoading(false);
        window.location.href = resp.consent_url; // Redirect to Amazon
      }
    } catch (error) {
      setLoading(false);
      toast.error(resp?.message, { position: "top-center" });
      console.error('Failed to fetch Amazon consent URL:', error);
    }
  };

  return (
    <Modal
      className={
        "anvKyc_tpModal "
      }
      show={showModal}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      size="xl"
      centered
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-90h"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Connect Seller Accounts:
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="kycProc_otrBdy">
        <Row xs={1} md={3} className="g-4 m-4">
          <Col>
            <Card disabled className="marketplaceCard disabled">
              <Card.Img variant="top" style={{ maxWidth: '50%', margin: 'auto', padding: '10px' }} src="https://assets-fincobox.s3.ap-south-1.amazonaws.com/noon-logo.svg" />
              <Card.Body>
                <Card.Title>Noon</Card.Title>
                <Card.Text>
                  Import data from your Noon Marketplace to unlock higher credit limit
                </Card.Text>
              </Card.Body>
              <Button disabled>Coming Soon</Button>
            </Card>
          </Col>
          <Col>
            <Card className="marketplaceCard connected">
              <Card.Img variant="top" style={{ maxWidth: '50%', margin: 'auto', padding: '10px' }} src="https://assets-fincobox.s3.ap-south-1.amazonaws.com/amazon-logo.jpg" />
              <Card.Body>
                <Card.Title>Amazon</Card.Title>
                <Card.Text>
                  Import data from your Amazon Marketplace to unlock higher credit limit
                </Card.Text>
              </Card.Body>
              {
                // connectedAmazonConnections?.length > 0 ?
                //   <div className={`statusTag success`}>
                //     <span className="success">
                //       {"Connected"}
                //     </span>
                //   </div>
                //   :
                <Button onClick={connectAmazonAccount}>{loading ? <Spinner size="sm" /> : 'Connect'}</Button>
              }
            </Card>
          </Col>
          <Col>
            <Card disabled className="marketplaceCard disabled">
              <Card.Img variant="top" style={{ maxWidth: '50%', margin: 'auto', padding: '10px' }} src="https://assets-fincobox.s3.ap-south-1.amazonaws.com/talabaat-logo.svg" />
              <Card.Body>
                <Card.Title>Talabaat</Card.Title>
                <Card.Text>
                  Import data from your Talabaat Marketplace to unlock higher credit limit
                </Card.Text>
              </Card.Body>
              <Button disabled>Coming Soon</Button>
            </Card>
          </Col>
        </Row>
      </Modal.Body>
    </Modal >
  );
};
