const version = "/v1";

export const methodType = {
  PUT: "put",
  GET: "get",
  POST: "post",
  DELETE: "delete",
  PATCH: "patch",
};

export const apiEndPoints = {
  authSignup: "/v1/auth/signup/",
  authLogin: "/v1/auth/login/",
  authVerifyMail: "/v1/auth/verify-mail/",
  authChangePassword: "/v1/auth/change-password/",
  resendEmailOtp: "/v1/auth/resend-email-otp/",
  verifyPhone: "/v1/auth/verify-phone/",
  resendOTP: "/v1/auth/resend-otp/",
  verifyPasswordReset: "/v1/auth/verify-password-reset/",
  resetPassword: "/v1/auth/reset-password/",

  applicationStatus: "/v1/loan/status",
  kycPersonal: "/v1/kyc/personal-kyc",
  kycBusiness: "/v1/kyc/business-kyc",
  kycStatus: "/v1/kyc/status",
  authCities: "/v1/auth/cities",
  getBanks: "/v1/auth/banks",
  authOccupation: "/v1/auth/occupation",
  authNationality: "/v1/auth/nationality",
  authIndustrySector: "/v1/auth/industry-sector",
  getUserBusinessKyc: "/v1/kyc/business-kyc/",
  updateUserKyc: "/v1/auth/user/",

  createLoan: "/v1/loan/loan",
  loanDocument: "/v1/loan/loan-document",
  evaluation: "/v1/loan/credit-evaluation",
  settlementBankAcount: "/v1/loan/settlement-bank-account",
  // Integrations

  fetchIntegrations: "/v1/integrations",
  connectAmazon: '/v1/integrations/amazon-connect',
  // Dynamic endpoint with loanId
  virtualAccount: (loanId) => `/v1/loan/${loanId}/virtual-accounts`,
  virtualAccountDocument: (loanId, vaId) => `/v1/loan/${loanId}/virtual-accounts/${vaId}/documents`,
};

export default apiEndPoints;
