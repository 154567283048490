import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from "@sentry/browser";

import App from './App.jsx'

// Initialize Sentry
Sentry.init({
  dsn: 'https://ccc796c471dca847630e8c633bae9dd5@o4508721995513856.ingest.de.sentry.io/4508721999183952', // Replace with your actual Sentry DSN
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

createRoot(document.getElementById('root')).render(
  <StrictMode>
    <App />
  </StrictMode>,
)
