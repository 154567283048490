import React, { useState } from "react";
import { MdDelete } from "react-icons/md";
import "./kyc.css";
import DropzoneField from "../../Common/DropzoneField";
import { checkFormError } from "../../../utils";
import { compareFiles } from '@/utils/utils';
import { invoiceFormSchema } from "../../../utils/error_schema";
import { deleteLoanDocumentRequest, createLoanDocumentRequest } from "../../../services";
import { Stack, Col, Row } from "react-bootstrap";

export function UploadLoanDocuments(props) {
  const { loanDocuments = [], setLoanDocuments, loanId, callLoan } = props;
  const [inputError, setInputError] = useState("");

  const handleRemoveClick = (data) => {
    // Find the index of the document to remove
    const updatedDocuments = [...loanDocuments];

    const itemIndexTobeDeleted = updatedDocuments.findIndex(existingFile => (existingFile.document_type === data.document_type && compareFiles(data.document, existingFile.document)));

    // const index = updatedDocuments.findIndex((doc) => doc.id === data?.id);
    if (itemIndexTobeDeleted !== -1) { // Check if the document exists
      updatedDocuments.splice(itemIndexTobeDeleted, 1); // Remove the document
      setLoanDocuments(updatedDocuments);

      // Delete the document if it has an ID
      if (data?.id) {
        deleteLoanDocumentRequest(data.id);
      }
    }
  };

  const uploadDocument = async (item) => {
    const formData = new FormData();
    if (typeof item.document !== "string") {
      formData.append("document_type", item.document_type);
      formData.append("document", item.document);
      formData.append("loan_id", loanId);
    }

    const response = await createLoanDocumentRequest(formData);
    if (response?.status === 201) {
      await callLoan(); // Refresh loan data
    }
  };

  const onFileUpload = async (documentType, files) => {
    const updatedDocuments = [...loanDocuments];

    // Add new files for the given document type
    files.forEach(async (file) => {
      updatedDocuments.push({ document_type: documentType, document: file });
      if (loanId) {
        await uploadDocument({ document_type: documentType, document: file })
      }
    });

    setLoanDocuments(updatedDocuments);

    // Validate form
    if (inputError) {
      const validationResult = await checkFormError(updatedDocuments, invoiceFormSchema);
      setInputError(validationResult);
    }
  };

  const openFileInNewTab = (file) => {
    if (typeof file === "string") {
      window.open(file, "_blank");
    } else {
      const fileUrl = URL.createObjectURL(file);
      window.open(fileUrl, "_blank");
    }
  };

  const DocumentUploadField = ({ document_type }) => (
    <div style={{ width: "100%" }} className="_attachBx">
      <DropzoneField
        showFilePreview={false}
        title="Upload Document"
        dropzoneProps={{
          noDrag: true,
          multiple: true,
        }}
        handleOnChange={(files) => onFileUpload(document_type, files)}
      />
      {
        inputError[document_type] && (
          <p className="dropFileErr invalid-feedback">{inputError[document_type]}</p>
        )
      }
    </div >
  );

  const DocumentsDisplay = ({ document_type }) => {
    const documentsToDisplay = loanDocuments.filter((doc) => doc.document_type === document_type);

    if (documentsToDisplay.length === 0) return null;

    return (
      <div className="responSive_table">
        <table className="table table-striped invMod_table">
          <thead>
            <tr>
              <th width="20%">S.no</th>
              <th width="40%">Uploaded Document</th>
              <th width="10%">Actions</th>
            </tr>
          </thead>
          <tbody>
            {documentsToDisplay.map((item, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>
                  <button
                    type="button"
                    className="viewBtn_table"
                    onClick={() => openFileInNewTab(item.document)}
                  >
                    View
                  </button>
                </td>
                <td>
                  <span
                    className="invMod_delIcon"
                    onClick={() => handleRemoveClick(item)}
                  >
                    <MdDelete />
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <Row>
      <Col xs={12} className="my-4">
        <Stack style={{ justifyContent: 'space-between' }}>
          <div>
            <h6>
              Upload Last 3 months Invoices<sup>*</sup>
            </h6>
            <span className="captionMsg">
              Please Upload your recent last 3 months invoices which you shared with the buyer/platform
            </span>
          </div>
          <div className="fileAdd_bx anvDropFile">
            <DocumentUploadField document_type="invoices" />
            <DocumentsDisplay document_type="invoices" />
          </div>
        </Stack>
      </Col>
      <Col xs={12} className="my-4">
        <Stack style={{ justifyContent: 'space-between' }}>
          <div>
            <h6>
              Please Upload 12 Month Bank Statements <sup>*</sup>
            </h6>
            <span className="captionMsg">
              Please ensure you add all your bank statements which are related to your business
              transactions for the last 12 months. This will help us quickly give you the best
              possible offer. Statements are needed for all revenue and expense bank accounts
              associated with your business. The PDF/Excel should be downloaded from your bank’s
              portal. Ensure to upload all the statements from all your bank accounts.
            </span>
          </div>
          <div className="fileAdd_bx anvDropFile">
            <DocumentUploadField document_type="bank_statements" />
            <DocumentsDisplay document_type="bank_statements" />
          </div>
        </Stack>
      </Col>
      <Col xs={6} className="my-4">
        <Stack style={{ justifyContent: 'space-between', height: '100%' }}>
          <div>
            <h6>AECB Credit Report</h6>
            <span className="captionMsg">Please upload your recent AECB credit report.</span>
          </div>
          <div className="fileAdd_bx anvDropFile">
            <DocumentUploadField document_type="aecb_credit_report" />
            <DocumentsDisplay document_type="aecb_credit_report" />
          </div>
        </Stack>
      </Col>
      <Col xs={6} className="my-4">
        <Stack style={{ justifyContent: 'space-between', height: '100%' }}>
          <div>
            <h6>Audited Financials</h6>
            <span className="captionMsg">
              Please upload your audited balance sheet, profit & loss statement, and cash flow
              statement from 1st April 2023 to 31st March 2024.
            </span>
          </div>
          <div className="fileAdd_bx anvDropFile">
            <DocumentUploadField document_type="audited_financials" />
            <DocumentsDisplay document_type="audited_financials" />
          </div>
        </Stack>
      </Col>
      <Col xs={6} className="my-4">
        <Stack style={{ justifyContent: 'space-between', height: '100%' }}>
          <div>
            <h6>Provisional Financials</h6>
            <span className="captionMsg">
              Please upload your provisional balance sheet and profit & loss statement from 1st April
              2024 to 31st October 2024.
            </span>
          </div>
          <div className="fileAdd_bx anvDropFile">
            <DocumentUploadField document_type="provisional_financials" />
            <DocumentsDisplay document_type="provisional_financials" />
          </div>
        </Stack>
      </Col>
    </Row>
  );
}
