import React from "react";
import { FloatingField, SelectField, InputField } from "../../Common";
import PhoneInput from "react-phone-input-2";

import "./kyc.css";

const PersonalDetailsForm = ({
  inputValue, inputError, handleOnChange, occupationData, nationalityData
}) => {
  return (
    <div className="_innerKyc_grid">
      <div className="_inKycHead">
        <h1>Personal Information</h1>
        <p>
          We need a few more details about you and your business
        </p>
      </div>

      <div className="_inFr_flexBx">
        <FloatingField
          controlId="floatingInput"
          label="First Name"
          labelClass="_inInput_fx"
          type="text"
          placeholder="First Name"
          name="first_name"
          onChange={({ target: { name, value } }) =>
            handleOnChange(name, value)
          }
          disabled
          focus={!!inputError?.first_name}
          error={inputError?.first_name}
          value={inputValue.first_name}
        />

        <FloatingField
          controlId="floatingInput"
          label="Last Name"
          labelClass="_inInput_fx"
          type="text"
          placeholder="Last Name"
          name="last_name"
          onChange={({ target: { name, value } }) =>
            handleOnChange(name, value)
          }
          disabled
          focus={!!inputError?.last_name}
          error={inputError?.last_name}
          value={inputValue.last_name}
        />
      </div>

      <div className="_inFr_flexBx">
        <div className="_inInput_fx _inDual_flex">
          <InputField
            inpId="anv_inpCont_si"
            inputProps={
              <PhoneInput
                countryCodeEditable={false}
                placeholder="Mobile Number"
                className="text-input caret-transparent"
                name="mobile_number"
                country={"ae"}
                onlyCountries={["ae"]}
                autoComplete="off"
              />
            }
          />
          <FloatingField
            controlId="floatingInput"
            label="Mobile Number"
            labelClass="anvMob_fq_bx"
            type="number"
            placeholder="Mobile Number"
            name="phone_number"
            defaultValue
            maxLength={50}
            onChange={({ target: { name, value } }) =>
              handleOnChange(name, value)
            }
            disabled
            focus={!!inputError?.phone_number}
            error={inputError?.phone_number}
            value={inputValue.phone_number}
          />
        </div>

        <FloatingField
          controlId="floatingInput"
          label="Email ID"
          labelClass=""
          type="text"
          placeholder="Email ID"
          name="email"
          onChange={({ target: { name, value } }) =>
            handleOnChange(name, value)
          }
          disabled
          focus={!!inputError?.email}
          error={inputError?.email}
          value={inputValue.email}
        />
      </div>

      <div className="_inFr_flexBx anvBas_select">
        <SelectField
          boxClass="basic-single"
          classNamePrefix="select"
          placeholder="Occupation"
          valueText="id"
          labelText="occupation"
          options={occupationData}
          name="occupation"
          error={
            inputError?.occupation
              ? inputError?.occupation
              : inputError?.["occupation.value"]
          }
          focus={
            !!inputError?.["occupation.value"] ||
            !!inputError?.occupation
          }
          selectedOption={inputValue.occupation}
          setSelectedOption={(value) =>
            handleOnChange("occupation", value)
          }
        />
        <SelectField
          boxClass="basic-single"
          classNamePrefix="select"
          placeholder="Nationality"
          valueText="id"
          labelText="nationality"
          options={nationalityData}
          name="nationality"
          error={
            inputError?.nationality
              ? inputError?.nationality
              : inputError?.["nationality.value"]
          }
          focus={
            !!inputError?.["nationality.value"] ||
            !!inputError?.nationality
          }
          selectedOption={inputValue.nationality}
          setSelectedOption={(value) =>
            handleOnChange("nationality", value)
          }
        />
      </div>
    </div>
  );
};

export default PersonalDetailsForm;
