import React, { useContext, useEffect, useState } from "react";
import { Spinner, Button, Modal, Nav, Tab } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";
import arrImg from "@/assets/cst_whtArrow.png";
import { modalFormSchema } from "@/utils/error_schema";
import {
  checkFormError,
  firstLetterCapital,
  formateDatewithJoin,
  getResponseAllErrors,
} from "@/utils";
import { getStorage, setStorage } from "@/utils/storage";
import {
  kycCTabMenu,
} from "@/utils/constant";
import {
  businessKycRequest,
  personalKycRequest,
  updateUserDetail,
} from "@/services";
import { custom_context } from "@/utils/custom_context";
import { SuccessKYCModal } from "./SuccessKYCModal ";
import Loader from "../../Common/Loader";
import PersonalDetailsForm from "./personal_details_form";
import ResidentialDetailsForm from "./residential_details_form";
import IdentityDetailsForm from "./indentity_details_form";
import IncorporationDetailsForm from "./incorporation_details_form";
import { KYC_INITIAL_FORM } from './constants';

import { useKycData } from "./use_kyc_data";

import "./kyc.css";

export function KYCModalLayout(props) {
  const { show, setShow } = props;
  const data = getStorage("logged-in")?.result;
  const { kycDetails, cityData, occupationData, nationalityData, industrySectorData, kycLoading, initialFormValue, setInitialFormValue, inputValue, setInputValue, refreshKycData } = useKycData(data?.business_type);
  const { setProfileData } = useContext(custom_context.ProfileData);
  const menuList = kycCTabMenu.reduce((itemArray, item) => {
    itemArray.push(...item?.submenu);
    return itemArray;
  }, []);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [inputError, setInputError] = useState("");
  const [activeStep, setActiveStep] = useState("gnl_info");
  const [verifiedStep, setVerifiedStep] = useState(menuList);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    CallUserInfo(data);
  }, []);

  useEffect(() => {
    if (
      kycDetails?.id
    ) {
      //   const occupationLabel = occupationData.find(
      //     (value) => value.id == kycDetails?.occupation
      //   );

      //   const nationalityLabel = nationalityData.find(
      //     (value) => value.id == kycDetails?.nationality
      //   );
      //   const cityLabel = cityData.find((value) => value.id == kycDetails?.city);

      //   const industrySectorLabel = industrySectorData.find(
      //     (value) => value.id == kycDetails?.industry_sector
      //   );
      //   setInputValue((s) => {
      //     const obj = {
      //       ...s,
      //       ...kycDetails,
      //       occupation: occupationLabel?.id
      //         ? {
      //           value: occupationLabel?.id,
      //           label: occupationLabel?.occupation,
      //         }
      //         : null,
      //       nationality: nationalityLabel?.id
      //         ? {
      //           value: nationalityLabel?.id,
      //           label: nationalityLabel?.nationality,
      //         }
      //         : null,
      //       city: cityLabel?.id
      //         ? {
      //           value: cityLabel?.id,
      //           label: cityLabel?.name,
      //         }
      //         : null,
      //       industry_sector: industrySectorLabel?.id
      //         ? {
      //           value: industrySectorLabel?.id,
      //           label: industrySectorLabel?.name,
      //         }
      //         : inputValue.industry_sector,
      //       business_segment: kycDetails?.business_segment
      //         ? {
      //           value: kycDetails?.business_segment,
      //           label: firstLetterCapital(kycDetails?.business_segment),
      //         }
      //         : null,
      //       company_type: kycDetails?.company_type
      //         ? {
      //           value: kycDetails?.company_type,
      //           label: firstLetterCapital(kycDetails?.company_type),
      //         }
      //         : null,
      //       sme_segment: kycDetails?.sme_segment
      //         ? {
      //           value: kycDetails?.sme_segment,
      //           label: firstLetterCapital(kycDetails?.sme_segment),
      //         }
      //         : null,
      //     };
      //     setInitialFormValue(obj);
      //     return obj;
      //   });
      if (+kycDetails?.completion_percentage === 100) {
        setVerifiedStep(menuList.map((item) => ({ ...item, status: true })));
      }
    }
  }, [
    kycDetails,
  ]);

  const handleClose = () => {
    setShow(false);
    setVerifiedStep(menuList);
    setInputValue(initialValue);
    setActiveStep("gnl_info");
    setInputError("");
  };

  const CallUserInfo = (data) => {
    setInputValue((s) => ({
      ...s,
      user: data.id,
      first_name: data?.first_name,
      last_name: data?.last_name,
      email: data?.email,
      business_type: data?.business_type,
      phone_number: data?.phone_number,
      industry_sector: data?.company_details?.company_type,
      business_name: data?.company_details?.company_name
    }));
  };

  const submit = async (step) => {
    console.log('step', step, activeStep);
    const validationResult = await checkFormError(
      inputValue,
      modalFormSchema(activeStep, step === undefined ? "final" : "")
    );
    const activeMenu = menuList.find((item) => item.value === activeStep),
      activeId = activeMenu?.id,
      stepID = menuList.find((item) => item.value === step)?.id;
    const isValid =
      (activeId === undefined && step === "incorp_detail") || activeId > stepID
        ? true
        : validationResult === true;
    if (isValid) {
      setIsLoading(true);
      const payload = {
        ...inputValue,
        business_segment: inputValue?.business_segment?.value,
        occupation: inputValue?.occupation?.value,
        nationality: inputValue?.nationality?.value,
        city: inputValue?.city?.value,
        company_city: inputValue?.company_city?.value,
        company_type: inputValue?.company_type?.value,
        industry_sector: inputValue?.industry_sector?.value,
        sme_segment: inputValue?.sme_segment?.value,
        status: "submitted_for_review",
      };
      if (inputValue?.emirates_id_expiry_date) {
        payload["emirates_id_expiry_date"] = formateDatewithJoin(
          inputValue?.emirates_id_expiry_date
        );
      }
      if (inputValue?.passport_expiry_date) {
        payload["passport_expiry_date"] = formateDatewithJoin(
          inputValue?.passport_expiry_date
        );
      }
      if (inputValue?.passport_issue_date) {
        payload["passport_issue_date"] = formateDatewithJoin(
          inputValue?.passport_issue_date
        );
      }
      delete payload.first_name;
      delete payload.last_name;
      delete payload.email;
      delete payload.created;
      delete payload.modified;
      delete payload.completion_percentage;

      const fromData = new FormData();
      for (const key in payload) {
        if (
          key === "address_proof" ||
          key === "emirates_id_front" ||
          key === "emirates_id_back" ||
          key === "business_document" ||
          key === "passport_back" ||
          key === "passport_front" ||
          key === "trade_license" ||
          key === "moa" ||
          key === "coi" ||
          key === "aoa" ||
          key === "partnership_deed"
        ) {
          if (
            Object.hasOwnProperty.call(payload, key) &&
            !!payload[key] &&
            typeof payload[key] !== "string"
          ) {
            fromData.append(key, payload[key]);
          }
        } else {
          if (Object.hasOwnProperty.call(payload, key) && !!payload[key]) {
            fromData.append(key, payload[key]);
          }
        }
      }
      if (
        stepID === undefined ||
        (activeId < stepID &&
          JSON.stringify(initialFormValue) !== JSON.stringify(inputValue))
      ) {
        handleKycRequest(fromData, step);
      } else {
        activeAndVerifiedStep(step);
      }
    } else {
      const nestErrorObj = {};
      for (const key in validationResult) {
        if (Object.hasOwnProperty.call(validationResult, key)) {
          if (!activeMenu?.filed.includes(key)) {
            nestErrorObj[key] = validationResult[key];
          }
        }
      }
      let errors = {
        resError: { ...inputError?.resError, ...nestErrorObj },
        ...validationResult,
      }
      setInputError(errors);
    }
  };

  const handleKycRequest = async (fromData, step) => {
    if (inputValue.business_type === data?.business_type) {
      updateUserKYC(fromData, step);
    } else {
      const result = await updateUserDetail({
        business_type: inputValue.business_type,
        id: inputValue?.user,
      });
      if (result?.results) {
        setStorage("logged-in", {
          ...getStorage("logged-in"),
          result: result?.results,
        });
        updateUserKYC(fromData, step);
      } else {
        setIsLoading(false);
      }
    }
  };

  const updateUserKYC = async (fromData, step) => {
    if (inputValue?.business_type === "Individual") {
      const resp = await personalKycRequest(
        fromData,
        inputValue?.id ? inputValue?.id : false
      );
      handleAPIResponse(resp, step);
    }

    if (inputValue?.business_type === "Business") {
      const res = await businessKycRequest(
        fromData,
        inputValue?.id ? inputValue?.id : false
      );
      handleAPIResponse(res, step);
    }
  };

  const handleAPIResponse = (resp, step) => {
    if (resp?.success) {
      const completion_percentage =
        resp?.results?.completion_percentage;
      const userDetails = {
        ...getStorage("logged-in")?.result,
        kyc_details: {
          completion_percentage,
          rejection_reason: resp?.results?.rejection_reason,
          status: resp?.results?.status,
        },
      };
      setStorage("logged-in", {
        ...getStorage("logged-in"),
        result: userDetails,
      });
      setProfileData(userDetails);
      activeAndVerifiedStep(step);
      if (step === undefined) {
        setShowSuccessModal(true);
      }
      setIsLoading(false);
      refreshKycData(inputValue?.business_type);
      setInputError("");
    } else {
      setIsLoading(false);
      setInputError((s) => ({
        ...s,
        ...getResponseAllErrors(resp?.error),
        resError: getResponseAllErrors(resp?.error),
      }));
    }
  };
  const activeAndVerifiedStep = (step) => {

    const activeMenu = menuList.find((item) => item.value === activeStep);
    if (step) {
      setActiveStep(step);
      setVerifiedStep((s) => {
        if (s.some((list) => list?.value === activeStep)) {
          s.push({ ...activeMenu, status: true });
        }
        return s;
      });
    }
    setIsLoading(false);
  };
  const handleOnChange = async (name, value) => {
    let stateObj = { ...inputValue, [name]: value };
    setInputValue((s) => {
      stateObj = { ...s, [name]: value };
      return stateObj;
    });
    if (!!inputError) { //false
      const activeMenu = menuList.find((item) => item.value === activeStep);
      const validationResult = await checkFormError(
        stateObj,
        modalFormSchema(activeStep)
      );
      // setInputError((s) => ({ resError: s.resError, ...error }));
      setInputError((s) => {
        const obj = {};
        for (const key in s?.resError) {
          if (Object.hasOwnProperty.call(s?.resError, key)) {
            if (!activeMenu?.filed.includes(key)) {
              obj[key] = s?.resError[key];
            }
          }
        }
        return {
          resError: { ...obj },
          ...validationResult,
        };
      });
    }
  };

  return (
    <Modal
      className={"anvKyc_tpModal " + (showSuccessModal ? "success_modal" : "")}
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="xl"
      centered
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          KYC Updation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="kycProc_otrBdy">
        {kycLoading && <div className="modalLoadingSpinner"><Spinner className="mx-2" /> Fetching KYC Details...</div>}
        {!kycLoading && cityData && <Tab.Container id="left-tabs-example" defaultActiveKey="gnl_info">
          <div className="kycProc_sdBar">
            <Nav variant="pills" className="flex-column">
              {kycCTabMenu.map((menu, ind) => (
                <React.Fragment key={menu?.label + ind}>
                  <div className="kycProc_TabHd">
                    <span>{menu?.label}</span>
                  </div>
                  {menu?.submenu.map((submenu, key) => (
                    <Nav.Item key={submenu?.label + key}>
                      <Nav.Link
                        type="submit"
                        onClick={() => submit(submenu?.value)}
                        className={
                          (submenu?.value === activeStep ? "active " : "") +
                          "d-flex justify-content-between"
                        }
                      >
                        {submenu?.label}
                        {verifiedStep.some(
                          (item) =>
                            item?.status === true &&
                            item?.value === submenu?.value
                        ) &&
                          submenu?.value !== activeStep && (
                            <span>
                              <FaCheckCircle
                                className="active_check_icon"
                                color="#21CEB6"
                              />
                            </span>
                          )}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </React.Fragment>
              ))}
            </Nav>
          </div>

          <div className="kycProc_sdContent">
            <div className="kycContent_ht">
              <Tab.Content>
                <Tab.Pane active={activeStep === "gnl_info"}>
                  <PersonalDetailsForm
                    handleOnChange={handleOnChange}
                    inputError={inputError}
                    inputValue={inputValue}
                    occupationData={occupationData}
                    nationalityData={nationalityData}
                  />
                </Tab.Pane>

                <Tab.Pane active={activeStep === "addrs"}>
                  <ResidentialDetailsForm
                    handleOnChange={handleOnChange}
                    inputError={inputError}
                    inputValue={inputValue}
                    cityData={cityData}
                  />
                </Tab.Pane>

                <Tab.Pane active={activeStep === "doc_verify"}>
                  <IdentityDetailsForm
                    handleOnChange={handleOnChange}
                    inputError={inputError}
                    inputValue={inputValue}
                  />
                </Tab.Pane>

                <Tab.Pane active={activeStep === "incorp_detail"}>
                  <IncorporationDetailsForm
                    industrySectorData={industrySectorData}
                    cityData={cityData}
                    handleOnChange={handleOnChange}
                    inputError={inputError}
                    inputValue={inputValue}
                  />
                </Tab.Pane>
              </Tab.Content>
            </div>
            <Modal.Footer>
              <div className={inputError?.resError && "anvFlex-root"}>
                {
                  inputError?.resError && (
                    <div>
                      {Object.keys(inputError.resError).map((key) => (
                        <p className="m-0 invalid-feedback" key={key}>
                          <b>{key} :</b> {inputError.resError[key]}
                        </p>
                      ))}
                    </div>
                  )
                }
                {/* <div>{inputError?.resError && responseErrors.map((item) => <p className="invalid-feedback">{item}</p>)}</div> */}
                <Button
                  variant="primary"
                  className="anvSv_btn"
                  onClick={() => {
                    submit(
                      menuList[
                        menuList.findIndex(
                          (item) => item?.value === activeStep
                        ) + 1
                      ]?.value
                    )
                  }
                  }
                  disabled={isLoading}
                >
                  <span>
                    <span class="text-base">Save & Continue</span>
                    {isLoading ? (
                      <Loader className="loader_kycBtn" />
                    ) : (
                      <img src={arrImg} className="arrCon_btn" />
                    )}
                  </span>
                </Button>
              </div>
            </Modal.Footer>
          </div>
          {showSuccessModal && (
            <SuccessKYCModal
              show={showSuccessModal}
              setShow={() => {
                setShowSuccessModal();
                handleClose();
              }}
            />
          )}
        </Tab.Container>
        }
      </Modal.Body >
    </Modal >
  );
}
