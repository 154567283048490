export const firstCharacterOfWord = (str = '') =>
  str.split(" ").length > 1
    ? str
      .split(" ")
      .map((item) => item.slice(0, 1).toUpperCase())
      .join("")
    : str.slice(0, 2).toUpperCase();


export const compareFiles = (file1, file2) => {
  if (!file1 || !file2) {
    return false;
  }

  return file1.name === file2.name &&
    file1.size === file2.size &&
    file1.type === file2.type &&
    file1.lastModified === file2.lastModified;
};
