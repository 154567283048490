import React from "react";
import { FloatingField, SelectField } from "../../Common";
import DropzoneField from "../../Common/DropzoneField";

import "./kyc.css";

const ResidentialDetailsForm = ({
  inputValue, inputError, handleOnChange, cityData
}) => {
  return (
    <div className="_innerKyc_grid" >
      <div className="_inKycHead">
        <h1>Address and Proof of Residence</h1>
        <p>
          Enter your current residential address
        </p>
      </div>

      <div className="_inFr_flexBx">
        <FloatingField
          className="w-100 "
          controlId="floatingInput"
          label="Address *"
          labelClass="_inInput_fx w-100 fullW-d100"
          as="textarea"
          placeholder="Address"
          name="address"
          onChange={({ target: { name, value } }) =>
            handleOnChange(name, value)
          }
          focus={!!inputError?.address}
          error={inputError?.address}
          value={inputValue.address}
        />
      </div>

      <div className="_inFr_flexBx anvBas_select">
        <SelectField
          boxClass="basic-single"
          classNamePrefix="select"
          placeholder="City"
          valueText="id"
          labelText="name"
          options={cityData}
          name="city"
          error={
            inputError?.city
              ? inputError?.city
              : inputError?.["city.value"]
          }
          focus={
            !!inputError?.["city.value"] || !!inputError?.city
          }
          selectedOption={inputValue.city}
          setSelectedOption={(value) =>
            handleOnChange("city", value)
          }
        />

        <FloatingField
          controlId="floatingInput"
          label="Enter your PO BOX"
          labelClass="_inInput_fx"
          type="text"
          placeholder="Enter your PO BOX"
          name="po_box"
          onChange={({ target: { name, value } }) =>
            handleOnChange(name, value)
          }
          focus={!!inputError?.po_box}
          error={inputError?.po_box}
          value={inputValue.po_box}
        />
      </div>

      <div className="_inFr_flexBx">
        <div className="fileAdd_bx">
          <p>Proof of Residential Address in UAE*</p>
          <div
            className={
              (inputError?.address_proof ? "error " : "") +
              "_attachBx"
            }
          >
            <DropzoneField
              title="Address proof"
              htmlFor="attach"
              value={
                !!inputValue.address_proof &&
                typeof inputValue.address_proof === "string"
              }
              handleOnChange={(value) =>
                handleOnChange("address_proof", value)
              }
            />
          </div>
          <span className="fil_addBottom_para">
            (Utility Bill, Bank Statement, Ejari, rental agreement etc)
          </span>

          {!!inputError?.address_proof && (
            <p className="invalid-feedback">
              {inputError?.address_proof}
            </p>
          )}
        </div>
      </div>
    </div >
  )
}

export default ResidentialDetailsForm;
