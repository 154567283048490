import React, { useEffect, useState } from "react";
import { Stack, Button, Card } from "react-bootstrap";
import { getEvaluation } from "@/services";

import { BankDetailsUpdateModal } from '@/components/Modal/application/bank_details_update_modal';

import '../dashboard_styles.css';
import { getSettlementBankAccounts } from "../../../services";
import { renderRejectionMessage } from "../Dashboard";

function CreditEvaluation({ disabled = false, documentStatus }) {
  const [evaluationLoading, setEvaluationLoading] = useState(false);
  const [bankDetailsUpdate, setBankDetailsUpdate] = useState(false);
  const [evaluationDetails, setEvaluationDetails] = useState({});
  const [bankAccountDetails, setBankAccountDetails] = useState({});

  let description = "Receive a tailored credit limit offer and start uploading invoices"
  let disableCard = disabled;
  const showAction = evaluationDetails?.status === 'approved';
  const stepComplete = bankAccountDetails?.status === 'verified';

  useEffect(() => {
    getEvaluationDetails();
    getBankAccountDetails();
  }, [])

  const getEvaluationDetails = async () => {
    setEvaluationLoading(true);
    try {
      const resp = await getEvaluation();
      if (resp?.status) {
        setEvaluationDetails(resp?.results[0]);
      }
    } catch (error) {
      console.error("Error fetching evaluation details:", error);
    } finally {
      setEvaluationLoading(false);
    }
  };

  const getBankAccountDetails = async () => {
    setEvaluationLoading(true);
    try {
      const resp = await getSettlementBankAccounts();
      if (resp?.status) {
        setBankAccountDetails(resp?.results[0]);
      }
    } catch (error) {
      console.error("Error fetching evaluation details:", error);
    } finally {
      setEvaluationLoading(false);
    }
  };


  if (bankAccountDetails?.status === 'verified') {
    disableCard = false;
    description = "We’ve successfully verified your acccount details."
  } else if (evaluationDetails?.status === 'rejected') {
    description = "We regret to inform that as per your current financials we cannot extend your a credit offer, Please re-apply in next 6 months"
  } else if (evaluationDetails?.status === 'approved') {
    description = `Congratulations, you’ve been granted a credit limit of AED '${evaluationDetails?.amount}'. Complete next steps and start uploading invoices`
    disableCard = false;
  } else if (evaluationDetails?.status === 'input_required') {
    description = "Additional information will be required to further assess your eligibility, We will be reaching out to you in next 48 hours."
    disableCard = false;
  } else if (evaluationDetails?.status === 'under_review') {
    description = "We are evaluating your eligibility, you will receive an email within 48 hours on Approval of your credit limit."
    disableCard = false;
  } else {
    description = "Receive a tailored credit limit offer and start uploading invoices."
    disableCard = true;
  }

  return (
    <Card className={`dashboardActionCard ${disableCard ? 'disabledCard' : 'activeCard'}`}>
      <Stack direction="horizontal" gap={3}>
        <Stack gap={2} className="actionContent">
          <h4 className="actionCardTitle dash_statusDiv">Evaluation & Credit Offer
            {!evaluationLoading && bankAccountDetails?.status &&
              <div className={`statusTag ${stepComplete || bankAccountDetails?.status === 'under_review' ? "success" : ""}`}>
                <span className={`${stepComplete || bankAccountDetails?.status === 'under_review' ? "success" : ""}`}>
                  {bankAccountDetails?.status_value}
                </span>
              </div>
            }
          </h4>
          {!evaluationLoading && <p className="actionCardDescription">{description}</p>}
        </Stack>
        <div>
          {
            showAction && <Button
              variant="primary"
              onClick={() => { setBankDetailsUpdate(true) }}
              disabled={!disableCard && !showAction}
              className="actionButton"
              color="primary"
            >
              View
            </Button>
          }
        </div>
      </Stack>
      {renderRejectionMessage(evaluationDetails?.status, evaluationDetails?.rejection_reason)}
      {renderRejectionMessage(bankAccountDetails?.status, bankAccountDetails?.rejection_reason)}
      {bankDetailsUpdate && <BankDetailsUpdateModal showModal={bankDetailsUpdate} evaluationDetails={evaluationDetails} onHide={() => { getBankAccountDetails(); setBankDetailsUpdate(false) }} />}
    </Card >
  );
}

export default CreditEvaluation;