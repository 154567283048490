import React from "react";
import {
  FloatingField,
  SelectField
} from "../../Common";
import DropzoneField from "../../Common/DropzoneField";
import {
  businessKycCompanyType,
  businessKycBusinessSegment,
  kycCTabMenu,
} from "@/utils/constant";
import "./kyc.css";

const IncorporationDetailsForm = ({
  inputValue, inputError, handleOnChange, industrySectorData, cityData
}) => {
  return (
    <div className="_innerKyc_grid ing_exMarg">
      <div className="_inKycHead">
        <h1>Incorporation details</h1>
        <p>
          We need a few more details about you and your business
        </p>
      </div>

      <div className="_inFr_flexBx anvBas_select">
        <FloatingField
          controlId="floatingInput"
          label="Your Business Name"
          labelClass="_inInput_fx"
          type="text"
          placeholder="Your Business Name"
          name="business_name"
          onChange={({ target: { name, value } }) =>
            handleOnChange(name, value)
          }
          focus={!!inputError?.business_name}
          error={inputError?.business_name}
          value={inputValue.business_name}
        />
        <SelectField
          boxClass="basic-single"
          classNamePrefix="select"
          placeholder="Type of company*"
          valueText="value"
          labelText="label"
          options={businessKycCompanyType}
          name="company_type"
          error={
            inputError?.company_type
              ? inputError?.company_type
              : inputError?.["company_type.value"]
          }
          focus={
            !!inputError?.["company_type.value"] ||
            !!inputError?.company_type
          }
          selectedOption={inputValue.company_type}
          setSelectedOption={(value) =>
            handleOnChange("company_type", value)
          }
        />
      </div>

      <div className="_inFr_flexBx anvBas_select">
        <SelectField
          boxClass="basic-single"
          classNamePrefix="select"
          placeholder="Select Business Segment*"
          valueText="value"
          labelText="label"
          options={businessKycBusinessSegment}
          name="business_segment"
          error={
            inputError?.business_segment
              ? inputError?.business_segment
              : inputError?.["business_segment.value"]
          }
          focus={
            !!inputError?.["business_segment.value"] ||
            !!inputError?.business_segment
          }
          selectedOption={inputValue.business_segment}
          setSelectedOption={(value) =>
            handleOnChange("business_segment", value)
          }
        />
        <SelectField
          boxClass="basic-single"
          classNamePrefix="select"
          placeholder="Industry Sector*"
          valueText="id"
          labelText="name"
          options={industrySectorData}
          name="industry_sector"
          error={
            inputError?.industry_sector
              ? inputError?.industry_sector
              : inputError?.["industry_sector.value"]
          }
          focus={
            !!inputError?.["industry_sector.value"] ||
            !!inputError?.industry_sector
          }
          selectedOption={inputValue.industry_sector}
          setSelectedOption={(value) =>
            handleOnChange("industry_sector", value)
          }
        />


      </div>

      <div className="_inFr_flexBx anvBas_select">

        <FloatingField
          controlId="floatingInput"
          label="Trade License Number*"
          labelClass="_inInput_fx"
          type="text"
          placeholder="Enter Trade License Number"
          name="trade_license_number"
          onChange={({ target: { name, value } }) =>
            handleOnChange(name, value)
          }
          focus={!!inputError?.trade_license_number}
          error={inputError?.trade_license_number}
          value={inputValue.trade_license_number}
        />

        <FloatingField
          controlId="floatingInput"
          label="Tax Registration Number"
          labelClass="_inInput_fx"
          type="text"
          placeholder="Enter Tax Registration Number"
          name="tin_number"
          onChange={({ target: { name, value } }) =>
            handleOnChange(name, value)
          }
          focus={!!inputError?.tin_number}
          error={inputError?.tin_number}
          value={inputValue.tin_number}
        />

      </div>

      <div className="_inFr_flexBx anvBas_select">

        <div className="fileAdd_bx">
          <p className="_upLoad_uPara">
            Trade License /Commercial Registration *
          </p>
          <div
            className={
              (inputError?.trade_license ? "error " : "") +
              "_attachBx"
            }
          >
            <DropzoneField
              title="Trade License / Commercial Registration"
              value={
                !!inputValue.trade_license &&
                typeof inputValue.trade_license === "string"
              }
              handleOnChange={(value) =>
                handleOnChange("trade_license", value)
              }
            />
          </div>
          {!!inputError?.trade_license && (
            <p className="invalid-feedback">
              {inputError?.trade_license}
            </p>
          )}
        </div>

        <div className="fileAdd_bx">
          <p className="_upLoad_uPara">
            Certificate of Incorporation
          </p>
          <div
            className={
              (inputError?.coi ? "error " : "") +
              "_attachBx"
            }
          >
            <DropzoneField
              title="Certificate of Incorporation"
              value={
                !!inputValue.coi &&
                typeof inputValue.coi === "string"
              }
              handleOnChange={(value) =>
                handleOnChange("coi", value)
              }
            />
          </div>
          {!!inputError?.coi && (
            <p className="invalid-feedback">
              {inputError?.coi}
            </p>
          )}
        </div>
      </div>


      <div className="_inFr_flexBx anvBas_select">

        <div className="fileAdd_bx">
          <p className="_upLoad_uPara">
            Articles of Association
          </p>
          <div
            className={
              (inputError?.aoa ? "error " : "") +
              "_attachBx"
            }
          >
            <DropzoneField
              title="Articles of Association"
              value={
                !!inputValue.aoa &&
                typeof inputValue.aoa === "string"
              }
              handleOnChange={(value) =>
                handleOnChange("aoa", value)
              }
            />
          </div>
          {!!inputError?.aoa && (
            <p className="invalid-feedback">
              {inputError?.aoa}
            </p>
          )}
        </div>

        <div className="fileAdd_bx">
          <p className="_upLoad_uPara">
            Memorandum of Association
          </p>
          <div
            className={
              (inputError?.moa ? "error " : "") +
              "_attachBx"
            }
          >
            <DropzoneField
              title="Memorandum of Association"
              value={
                !!inputValue.moa &&
                typeof inputValue.moa === "string"
              }
              handleOnChange={(value) =>
                handleOnChange("moa", value)
              }
            />
          </div>
          {!!inputError?.moa && (
            <p className="invalid-feedback">
              {inputError?.moa}
            </p>
          )}
        </div>
      </div>

      <div className="_inFr_flexBx anvBas_select">

        <div className="fileAdd_bx">
          <p className="_upLoad_uPara">
            Shareholder / Partnerships Agreement
          </p>
          <div
            className={
              (inputError?.partnership_deed ? "error " : "") +
              "_attachBx"
            }
          >
            <DropzoneField
              title="Shareholder / Partnerships Agreement"
              value={
                !!inputValue.partnership_deed &&
                typeof inputValue.partnership_deed === "string"
              }
              handleOnChange={(value) =>
                handleOnChange("partnership_deed", value)
              }
            />
          </div>
          {!!inputError?.partnership_deed && (
            <p className="invalid-feedback">
              {inputError?.partnership_deed}
            </p>
          )}
        </div>
      </div>

      <div className="_inKycHead">
        <h1>Office Address</h1>
      </div>

      <div className="_inFr_flexBx">
        <FloatingField
          className="w-100 "
          controlId="floatingInput"
          label="Address *"
          labelClass="_inInput_fx w-100 fullW-d100"
          as="textarea"
          placeholder="Address"
          name="company_address"
          onChange={({ target: { name, value } }) =>
            handleOnChange(name, value)
          }
          focus={!!inputError?.company_address}
          error={inputError?.company_address}
          value={inputValue.company_address}
        />
      </div>

      <div className="_inFr_flexBx anvBas_select">
        <SelectField
          boxClass="basic-single"
          classNamePrefix="select"
          placeholder="City"
          valueText="id"
          labelText="name"
          options={cityData}
          name="company_city"
          error={
            inputError?.city
              ? inputError?.city
              : inputError?.["company_city.value"]
          }
          focus={
            !!inputError?.["company_city.value"] || !!inputError?.city
          }
          selectedOption={inputValue.company_city}
          setSelectedOption={(value) =>
            handleOnChange("company_city", value)
          }
        />

        <FloatingField
          controlId="floatingInput"
          label="Enter your PO BOX"
          labelClass="_inInput_fx"
          type="text"
          placeholder="Enter your PO BOX"
          name="company_po_box"
          onChange={({ target: { name, value } }) =>
            handleOnChange(name, value)
          }
          focus={!!inputError?.company_po_box}
          error={inputError?.company_po_box}
          value={inputValue.company_po_box}
        />
      </div>

      <div className="_inFr_flexBx">
        <div className="fileAdd_bx">
          <p>Proof of Office Address in UAE*</p>
          <div
            className={
              (inputError?.business_document ? "error " : "") +
              "_attachBx"
            }
          >
            <DropzoneField
              title="Address proof"
              htmlFor="attach_6"
              value={
                !!inputValue.business_document &&
                typeof inputValue.business_document === "string"
              }
              handleOnChange={(value) =>
                handleOnChange("business_document", value)
              }
            />
          </div>
          <span className="fil_addBottom_para">
            (Ejari and 3 months Utility Bill)
          </span>

          {!!inputError?.business_document && (
            <p className="invalid-feedback">
              {inputError?.business_document}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default IncorporationDetailsForm;