import React, { useState, useEffect } from "react";
import { Stack, Button, Card } from "react-bootstrap";
import { getVirtualAccountsRequest } from '@/services';
import { VirtualAccountDetailsModal } from "../../../components/Modal/application/virtual_account_details_modal";

import '../dashboard_styles.css';

function AccountSetup({ loanId }) {
  const [virtualAccountsLoading, setVirtualAccountsLoading] = useState(false);
  const [vaDetails, setVaDetails] = useState({});
  const [showModal, setShowModal] = useState(false);

  let description = "We will be setting up your collection account which you will update/share with your Buyer"
  let disableCard = true;

  if (!!vaDetails?.status) {
    if (vaDetails?.status === 'approved') {
      description = "Congratulations, your account change has been approved."
      disableCard = false;
    } else if (vaDetails?.status === 'under_review') {
      description = "We are activating your credit line this would take 24 hours "
      disableCard = false;
    } else if (vaDetails?.status === 'rejected') {
      description = vaDetails?.remarks || 'Please upload proper proof for account change'
      disableCard = false;
    } else {
      description = "Congratulations, your account has been successfully setup, Please share/update these details with your buyer/platform"
      disableCard = false;
    }
  }

  const isValidState = ['under_review', 'approved'].includes(vaDetails?.status);

  useEffect(() => {
    getVADetails();
  }, [loanId])

  const getVADetails = async () => {
    if (loanId) {
      setVirtualAccountsLoading(true);
      try {
        const resp = await getVirtualAccountsRequest({ loanId });
        if (resp?.status) {
          setVaDetails(resp?.results[0]);
        }
      } catch (error) {
        console.error("Error fetching Virtual Account details:", error);
      } finally {
        setVirtualAccountsLoading(false);
      }
    }
  };

  return (
    <Card className={`dashboardActionCard ${disableCard ? 'disabledCard' : 'activeCard'}`}>
      <Stack direction="horizontal" gap={3}>
        <Stack gap={2} className="actionContent">
          <h4 className="actionCardTitle dash_statusDiv">Account Setup
            {!virtualAccountsLoading && vaDetails?.status !== 'created' &&
              <div className={`statusTag ${isValidState ? "success" : ""}`}>
                <span className={`${isValidState ? "success" : ""}`}>
                  {vaDetails?.status_value}
                </span>
              </div>
            }
          </h4>
          <p className="actionCardDescription">{description}</p>
        </Stack>
        <div>
          <Button
            variant="primary"
            onClick={() => { setShowModal(true) }}
            disabled={!vaDetails}
            className="actionButton"
            color="primary"
          >
            {isValidState ? 'View' : 'Setup'}
          </Button>
        </div>
      </Stack>
      <VirtualAccountDetailsModal
        disableActions={isValidState}
        showModal={showModal}
        loanId={loanId}
        reloadVa={getVADetails}
        vaDetails={vaDetails}
        onHide={() => { setShowModal(false) }}
      />
    </Card >
  );
}

export default AccountSetup;